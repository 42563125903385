import { inject } from "fw";
import { dispatch, handle, Store } from "fw-state";
import { debounce } from "lodash-es";
import moment from "moment";

import { StartAction, LogoutAction } from "./actions";
import { FormErrorHandling } from "./error-handling";

import { CalendarEventRegistrationRepository } from "network/calendar-event-registration-repository";
import { CalendarEventRepository } from "network/calendar-event-repository";
import { CalendarEventSeriesRepository } from "network/calendar-event-series-repository";

import { FeatureFlagService } from "service/feature-flag";

import {
  CalendarEvent,
  CalendarEventInfo,
  CalendarEventInstanceStats,
  CalendarEventSeries,
  CalendarEventSeriesStats,
  cleanCalendarEventSeries,
  cleanUpdateCalendarEventModel,
} from "models/calendar-event";
import {
  FormForCalendarEvent,
  FormForUpdateCalendarEventModel,
  FormForCalendarEventSeries
} from "forms/calendar-event";

export class UpdateCalendarEventSeriesAction {
  public updated: CalendarEventSeries = null;
  constructor(public form: FormForCalendarEventSeries) { }
}

export class CalendarEventSeriesUpdatedAction {
  constructor(public updated: CalendarEventSeries) { }
}

export class DeleteCurrentCalendarEventAction {
  constructor(public form: FormForCalendarEvent) { }
}

export class RefreshCalendarSeriesTotalsAction {
  constructor(public force: boolean = false) { }
}

export class RefreshCalendarInstanceTotalsAction {
  constructor() { }
}

export class FetchCurrentCalendarEventAction {
  public series: CalendarEventSeries = null;
  public instance: CalendarEvent = null;
  public instanceInfos: CalendarEventInfo[] = [];
  constructor(
    public force: boolean = false,
    public seriesId: string = null,
    public instanceKey: string = null
  ) { }
}

export class UpdateCalendarEventAction {
  public updated: CalendarEvent = null;
  constructor(public form: FormForUpdateCalendarEventModel) { }
}

interface CurrentCalendarEventShape {
  error: boolean;
  loaded: boolean;
  loading: boolean;
  instance: CalendarEvent;
  instanceInfos: CalendarEventInfo[];
  instanceStats: CalendarEventInstanceStats;
  isSeriesLoaded: boolean;
  series: CalendarEventSeries;
  seriesStats: CalendarEventSeriesStats;
  upcomingOccurrences: CalendarEventInfo[];
}
export type CurrentCalendarEvent = CurrentCalendarEventShape;

@inject
export class CurrentCalendarEventStore extends Store<CurrentCalendarEventShape>  {
  private debouncedRefreshSeriesTotals = async () => { };

  constructor(
    private calendarEventRegistrationRepo: CalendarEventRegistrationRepository,
    private calendarEventSeriesRepo: CalendarEventSeriesRepository,
    private calendarEventRepo: CalendarEventRepository,
    private ffs: FeatureFlagService,
  ) {
    super();
    this.debouncedRefreshSeriesTotals = debounce(() => this.refreshSeriesTotals(), 2000, { maxWait: 5000 });
  }

  private getDateString(targetDate: Date = new Date()): string {
    let target = moment(targetDate, null, true);
    if (!target.isValid()) {
      console.log('Error converting target occurrence date: Invalid date');
      target = moment();
    }

    return target.format('YYYY-MM-DD');
  }

  public filterUpcomingOccurrences(instances: CalendarEventInfo[], targetDate: Date = new Date()): CalendarEventInfo[] {
    const dateString = this.getDateString(targetDate);
    return instances?.filter(e => e?.date?.split('T')[0] >= dateString);
  }

  public shouldFilterUpcomingOccurrences(nextUpcomingInstance: CalendarEventInfo, targetDate: Date = new Date()): boolean {
    const dateString = this.getDateString(targetDate);
    return nextUpcomingInstance?.date?.split('T')[0] < dateString;
  }

  defaultState() {
    return {
      error: false,
      loaded: false,
      loading: false,
      instance: null,
      instanceInfos: [],
      instanceStats: null,
      isSeriesLoaded: false,
      series: null,
      seriesStats: null,
      upcomingOccurrences: [],
    };
  }

  @handle(StartAction)
  @handle(LogoutAction)
  private handleDefaults() {
    this.setState(() => this.defaultState());
  }

  @handle(UpdateCalendarEventSeriesAction, FormErrorHandling)
  private async handleUpdateCalendarEventSeries(action: UpdateCalendarEventSeriesAction) {
    action.form.validate();
    let newSeries = cleanCalendarEventSeries(action.form.updatedModel());
    newSeries = await this.calendarEventSeriesRepo.put(newSeries);
    action.updated = newSeries;
    const { series, instance } = this.state;
    if (series?.id === newSeries?.id) {
      this.setState(state => ({...state, series: newSeries }));
      await dispatch(new RefreshCalendarSeriesTotalsAction());
    }

    await dispatch(new CalendarEventSeriesUpdatedAction(newSeries));
  }

  @handle(FetchCurrentCalendarEventAction)
  private async handleFetchCurrentCalendarEvent(action: FetchCurrentCalendarEventAction) {
    if (this.state.loading || !action?.seriesId) return;

    const { series, instance } = this.state;
    const isSeriesLoaded = action?.seriesId === series?.id;
    const loaded = (
      isSeriesLoaded
      && action?.instanceKey === instance?.instance_key
    );

    if (!action?.force && loaded) return;

    this.setState(state => ({...state, loaded, loading: true }));

    try {
      let seriesInfo;
      if (!isSeriesLoaded) {
        seriesInfo = await this.calendarEventSeriesRepo.getInfo(action?.seriesId);
      }

      const newInstance = action?.instanceKey ? await this.calendarEventRepo.get(action?.seriesId, action?.instanceKey) : null;
      const updatedState = {
        loaded: true,
        loading: false,
        error: false,
        instance: newInstance,
        isSeriesLoaded,
      };
      if (!isSeriesLoaded) {
        updatedState['instanceInfos'] = seriesInfo.calendar_event_infos;
        updatedState['series'] = seriesInfo.calendar_event_series;
        updatedState['upcomingOccurrences'] = this.filterUpcomingOccurrences(updatedState['instanceInfos']);
      } else {
        if (this.state?.upcomingOccurrences?.length && this.shouldFilterUpcomingOccurrences(this.state?.upcomingOccurrences[0])) {
          updatedState['upcomingOccurrences'] = this.filterUpcomingOccurrences(this.state?.upcomingOccurrences);
        }
      }

      this.setState(state => ({
        ...state,
        ...updatedState,
      }));
      action.instance = newInstance;
      action.instanceInfos = !isSeriesLoaded ? updatedState['instanceInfos'] : this.state?.instanceInfos;
      action.series = !isSeriesLoaded ? updatedState['series'] : this.state?.series;

    } catch {
      this.setState(state => ({
        ...state,
        loaded: true,
        loading: false,
        error: true,
      }));
    }

    if (this.ffs.isFeatureFlagEnabled("ExpandEditsToPublishedEventsOUT3758Apr2024")) {
      await dispatch(new RefreshCalendarInstanceTotalsAction());
    } else {
      await dispatch(new RefreshCalendarSeriesTotalsAction(action?.force));
    }
  }

  @handle(RefreshCalendarSeriesTotalsAction)
  private async handleRefreshCalendarSeriesTotals(action: RefreshCalendarSeriesTotalsAction) {
    if (action.force)
      await this.refreshSeriesTotals();
    else
      await this.debouncedRefreshSeriesTotals();
  }

  private async refreshSeriesTotals() {
    const seriesId = this.state.series?.id;
    if (!seriesId)
      return;
    
    const seriesStats = await this.calendarEventSeriesRepo.getCounts(seriesId);
    this.setState(state => ({
      ...state,
      seriesStats
    }));
  }

  @handle(RefreshCalendarInstanceTotalsAction)
  private async handleRefreshCalendarInstanceTotals() {
    await this.refreshInstanceTotals();
  }

  private async refreshInstanceTotals() {
    this.setState(state => ({
      ...state,
      instanceStats: null,
    }));

    const seriesId = this.state.series?.id;
    if (!seriesId) return;
    
    const instanceKey = this.state.instance?.instance_key;
    if (!instanceKey) return;

    const instanceStats = await this.calendarEventRegistrationRepo.getInstanceCounts(seriesId, instanceKey);
    if (!!instanceStats) {
      this.setState(state => ({
        ...state,
        instanceStats,
      }));
    }
  }

  @handle(UpdateCalendarEventAction)
  private async handleUpdateCalendarEvent(action: UpdateCalendarEventAction) {
    if (!action.form)
      return;

    action.form.validate();
    const updateModel = cleanUpdateCalendarEventModel(action.form.updatedModel());
    const updated = await this.calendarEventRepo.put(updateModel)
    action.updated = updated;
    const seriesInfo = await this.calendarEventSeriesRepo.getInfo(updated.calendar_event_series_id);
    this.setState(state => ({...state,
      loaded: true,
      loading: false,
      error: false,
      series: seriesInfo.calendar_event_series,
      instance: updated,
      instanceInfos: seriesInfo.calendar_event_infos,
    }));
  }

  @handle(DeleteCurrentCalendarEventAction)
  private async handleDeleteCurrentCalendarEvent(action: DeleteCurrentCalendarEventAction) {
    if (!action.form) return;
    const { calendar_event_series_id, instance_key } = action.form;
    if (!calendar_event_series_id || !instance_key)
      return;
    await this.calendarEventRepo.delete(calendar_event_series_id, instance_key);
    await this.refreshSeriesTotals();
  }
}
