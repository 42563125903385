import { inject, prop } from "fw";

import { PopoverService } from "service/popover";
import { PermissionDescriptionPopover } from "./permission-description-popover";
import { SETTINGS_PAGES } from "views/settings/settings-constants";

@inject
export class PermissionTooltip {
  @prop()
  permission: string;

  public get permissionTooltip(): { [permission: string]: string[] } {
    const permissions = {
      OrganizationSettings: [
        SETTINGS_PAGES.APIKeys,
        SETTINGS_PAGES.CEEBData,
        SETTINGS_PAGES.SystemActivity,
        SETTINGS_PAGES.ContactDataSources,
        SETTINGS_PAGES.ContactRoutingTables,
        SETTINGS_PAGES.ContactSummaryViews,
        SETTINGS_PAGES.General,
      ],
      ManageExports: [
        SETTINGS_PAGES.ExportDestinations,
        SETTINGS_PAGES.Exports,
        SETTINGS_PAGES.TranslationTables,
      ],
      ManageApplicationSettings: [
        SETTINGS_PAGES.ApplicationProperties,
        SETTINGS_PAGES.ApplicationSummaryView,
        SETTINGS_PAGES.ProgramProperties,
      ],
      ManageEvaluationPhases: [
        SETTINGS_PAGES.Phases,
        SETTINGS_PAGES.ReviewRoutingTables,
      ],
      ManageAutomationRules: [
        SETTINGS_PAGES.Automation,
        SETTINGS_PAGES.Workflows,
      ],
      ManageContactType: [SETTINGS_PAGES.ContactTypes],
      ManageContactSegments: ["Contact Segments for All Contact Types"],
    };

    return permissions;
  }

  constructor(
    private popoverService: PopoverService
  ) {}

  async openPermissionDescription() {
    await this.popoverService.open(PermissionDescriptionPopover, {
      allowedPages: this.permissionTooltip[this.permission],
    });
  }
}
