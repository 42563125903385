export * from "../../../form-runtime/src/enums";
export * from "../../../form-runtime/src/models";

import { Form, Question } from "../../../form-runtime/src/models";
import { FormTypeCode } from "../../../form-runtime/src/enums";

export const allQuestions = (f: Form): Question[] => {
  const questions: Question[] = [];

  for (const section of f.Sections) {
    for (const q of section.Questions) {
      questions.push(q);
    }
  }

  return questions;
};

export class FormPathResult {
  FormTypeCode?: FormTypeCode = null;
  FormTypeName?: string = null;
  FormKey: string = null;
  FormName?: string = null;
  SectionKey?: string = null;
  SectionTitle?: string = null;
  QuestionKey: string = null;
  QuestionLabel?: string = null;
  QuestionText?: string = null;
  IsValid: boolean = false;
}

export const getName = (code: FormTypeCode) => {
  switch (code) {
    case FormTypeCode.Applicant:
      return "Applicant Form";
    case FormTypeCode.Recommender:
      return "Reference Form";
    case FormTypeCode.Evaluation:
      return "Evaluation Form";
    case FormTypeCode.Inquiry:
      return "Inquiry Form";
    default:
      return "Form";
  }
};
