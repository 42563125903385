import { inject, prop, needs, ComponentEventBus } from "@derekpitt/fw";
import { createFrom } from "fw-model";
import {
  FormAnswer,
  Form,
  FormSection,
  TableSectionRowFormData,
} from "../models";
import type {
  FileHash,
  FileService,
  CeebRequest,
  DecryptData,
} from "../models";
import { DialogService } from "fw-dialog";
import { AddTableSectionRow } from "./add-table-section-row";
import { Question } from "./question";
import { Restrictable } from "./restrictable";

@inject
@needs(Question, Restrictable)
export class SectionTable {
  @prop(null)
  form: Form;

  @prop(null)
  section: FormSection;

  @prop(null)
  component;

  @prop(null)
  answer: FormAnswer;

  @prop(null)
  baseAnswers: FormAnswer[];

  @prop(false)
  readonly: boolean;

  @prop(null)
  fileHash: FileHash;

  @prop(null)
  fileService: FileService;

  @prop(null)
  requestCeeb: CeebRequest;

  @prop(null)
  decryptData: DecryptData;

  @prop(false)
  displayonly: boolean;

  @prop(true)
  showValidation: boolean;

  @prop(true)
  showOptions: boolean;

  @prop(() => [])
  restrictions: string[];

  constructor(
    private dialogServer: DialogService,
    private ceb: ComponentEventBus,
  ) { }

  get canAdd() {
    if (this.section == null) return false;
    const {
      MaxRowCount,
      MinRowCount,
      NamedRows,
    } = this.section.TableSectionOptions;
    if (NamedRows != null && NamedRows.length > 0) return false;

    if (MaxRowCount == 0 && MinRowCount == 0) return true;

    if (this.answer == null) return false;
    if (this.answer.SectionAnswers.length >= MaxRowCount) return false;

    return true;
  }

  get canRemove() {
    if (this.section == null) return false;
    const {
      MaxRowCount,
      MinRowCount,
      NamedRows,
    } = this.section.TableSectionOptions;
    if (NamedRows != null && NamedRows.length > 0) return false;

    if (MaxRowCount == 0 && MinRowCount == 0) return true;
    if (this.answer == null) return false;
    if (this.answer.SectionAnswers.length <= MinRowCount) return false;

    return true;
  }

  get hasActions() {
    if (this.readonly || this.displayonly) return false;
    if (this.section == null) return false;
    const { NamedRows } = this.section.TableSectionOptions;
    if (NamedRows != null && NamedRows.length > 0) return false;

    return true;
  }

  get isNamed() {
    if (this.section == null) return false;
    const { NamedRows } = this.section.TableSectionOptions;
    if (NamedRows != null && NamedRows.length > 0) return true;

    return false;
  }

  async addRow() {
    const res = await this.openRowDialog();

    if (res.canceled) return;

    const newRow = new TableSectionRowFormData();
    newRow.Answers = res.result;

    this.answer.SectionAnswers.push(newRow);

    this.ceb.dispatch("update:answer", this.answer);
    this.ceb.dispatch("answer-changed");
  }

  get rows() {
    if (this.answer == null) return [];

    const rows: { answers: any[]; key: string, label: string, section_answer: TableSectionRowFormData, isInvalid: boolean; }[] = [];

    for (const a of this.answer.SectionAnswers) {
      const answers: FormAnswer[] = [];

      for (const q of this.section.TableSectionOptions.Columns) {
        // TODO: add support for indexing into complex options
        answers.push(a.Answers.find(aa => aa.QuestionKey == q.FieldPath));
      }

      const namedRow = (this.section.TableSectionOptions.NamedRows || []).find(nr => nr.Key == a.RowKey);

      const allAnswersValid = answers.filter(a => a !== undefined).every(a => a.MetaData.IsValid);

      rows.push({
        answers,
        key: a.RowKey,
        label: namedRow ? namedRow.Name : a.RowKey,
        section_answer: a,
        isInvalid: !allAnswersValid,
      });
    }

    return rows;
  }

  get invalid() {
    return this.rows.some(r => r.isInvalid);
  }

  formatAnswer(answer: FormAnswer) {
    return answer.Text;
  }

  getQuestion(answer: FormAnswer) {
    if (answer == null) return null;
    return this.section.Questions.find(q => q.Key == answer.QuestionKey);
  }

  removeRow(idx: number) {
    this.answer.SectionAnswers.splice(idx, 1);
    this.ceb.dispatch("update:answer", this.answer);
    this.ceb.dispatch("answer-changed");
  }

  async openRowDialog(answers: FormAnswer[] = []) {
    const miniForm = createFrom(Form, null);
    miniForm.Key=this.form.Key;
    miniForm.CalculatedFields = [];
    miniForm.Sections = [createFrom(FormSection, null)];
    miniForm.Sections[0].Questions = this.section.Questions;


    return await this.dialogServer.open<FormAnswer[]>(AddTableSectionRow, {
      form: miniForm,
      component: this.component,
      baseAnswers: this.baseAnswers || [],
      answers,
      isNew: answers.length == 0,
      restrictions: this.restrictions,

      questionOptions: {
        readonly: this.readonly,
        fileHash: this.fileHash,
        fileService: this.fileService,
        requestCeeb: this.requestCeeb,
        decryptData: this.decryptData,
        displayonly: this.displayonly,
        showValidation: this.showValidation,
        showOptions: this.showOptions,
      }
    }, { cssClass: "add-section-row" });
  }

  async editRow(row: { answers: FormAnswer[]; key: string; section_answer: TableSectionRowFormData }) {
    const res = await this.openRowDialog(row.answers);
    if (res.canceled) return;

    row.section_answer.Answers = res.result;
    this.ceb.dispatch("update:answer", this.answer);
    this.ceb.dispatch("answer-changed");
  }
}
