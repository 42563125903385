import { inject, Navigator } from "fw";
import { dispatch } from "fw-state";

import { ForgotPasswordForm } from "forms/forgotpassword";
import { UserRepository } from "network/user-repository";
import { CurrentOrganizationStore } from "state/current-organization";

@inject
export class ForgotPassword {
  private forgotPasswordForm = new ForgotPasswordForm();
  private loading = false;
  private isDone = false;

  constructor(
      private nav: Navigator,
      private userRepo: UserRepository,
      private currentOrgStore: CurrentOrganizationStore
    ) {}

  private async forgot() {
    this.loading = true;

    try {
      this.forgotPasswordForm.validate();
      await this.userRepo.forgotPassword(this.forgotPasswordForm.EmailAddress.trim(), this.currentOrgStore.state.portalContext?.OrganizationId);
      this.isDone = true;
    } catch (err) {}

    this.loading = false;
  }
}
