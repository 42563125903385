import { ComponentEventBus, inject, prop } from "fw";
import { isEmpty, keys } from "lodash-es";

import { Form, FormTypeCode } from "models/form";
import {
  ApplicationRestriction,
  type ApplicationRestrictionType,
  calculatedFieldPath,
  formPath,
  questionPath,
} from "models/role";
import { FormStore } from "state/forms";
import { orderBy } from "lodash-es";
import { FeatureFlagService } from "service/feature-flag";

type FormFieldRestriction = { label: string; key: string; path: string; title?: string; form?: Form }

@inject
export class FormRestrictions {
  @prop(null) type!: ApplicationRestrictionType;
  @prop(() => []) activeRestrictions!: ApplicationRestriction[];

  private formPath = formPath;
  private existingHash = {};
  private name = null;
  private searchTerm = "";
  private selectedHash = {};
  private selectedForm: Form = null;

  private formInfos: { form: Form; fieldCount: number }[] = [];
  private items: FormFieldRestriction[] = [];

  constructor(
    private ceb: ComponentEventBus,
    private formStore: FormStore,
    private ffs: FeatureFlagService
  ) { }

  attached() {
    const existingHash = {};
    for (const restriction of this.activeRestrictions.filter(r => r.Type == this.type) || []) {
      for (const path of restriction.Paths) {
        existingHash[path.startsWith("forms.") ? path.substring(6) : path] = true;
      }
    }
    this.existingHash = existingHash;

    this.setup();
  }

  setup() {
    const formInfos: { form: Form, fieldCount: number }[] = [];
    const items: FormFieldRestriction[] = [];

    const state = this.formStore.state.forms;
    if (state && state.length > 0) {
      for (const form of state) {
        switch (form.MetaData.Type) {
          case FormTypeCode.Applicant:
            if (this.type != "FormStep") continue;
            this.name = this.name || "Applicant";
            break;
          case FormTypeCode.Evaluation:
            if (this.type != "Evaluation") continue;
            this.name = this.name || "Evaluation";
            break;
          case FormTypeCode.Recommender:
            if (this.type != "ReferenceForm") continue;
            this.name = this.name || "Reference";
            break;
          default: continue;
        }

        let count = 0;
        for (const section of form.Sections) {
          for (const question of section.Questions) {
            count++;
            items.push({
              label: question.Label,
              title: question.Text,
              key: question.Key,
              path: questionPath(form, section, question),
              form: form,
            });
          }
        }

        // calc fields
        if (form.CalculatedFields) {
          count += form.CalculatedFields.length;
          form.CalculatedFields.forEach(calcField => {
            items.push({
              label: calcField.Label,
              key: calcField.Key,
              path: calculatedFieldPath(form, calcField),
              form: form
            });
          });
        }

        formInfos.push({ form: form, fieldCount: count });
      }
    }

    this.formInfos = orderBy(formInfos, [f => f.form.Name.toLowerCase()]);
    this.items = items;

  }

  public get localType() {
    return this.type;
  }
  public set localType(type: ApplicationRestrictionType) {
    this.ceb.dispatch("update:type", type);
  }

  get searchText() {
    return this.selectedForm ? `Search in ${this.selectedForm.Name}...` : `Search ${this.name} Forms...`;
  }

  get buttonText() {
    if (!this.selectedForm && this.searchTerm.length > 0)
      return "Add Selections";

    return (this.selectedHash[formPath(this.selectedForm, null, true)]
      ? "Add Form"
      : "Add Selections");
  }

  get hasSelectedValues() {
    if (!this.selectedHash || isEmpty(this.selectedHash))
      return false;

    const paths = keys(this.selectedHash);
    return paths && paths.length > 0;
  }

  get filtered() {
    if (!this.items || this.items.length === 0)
      return [];

    if (!this.searchTerm || this.searchTerm.length === 0) {
      return this.selectedForm ? this.items.filter(f => f.path.startsWith(`${formPath(this.selectedForm)}.`)) : this.items;
    }

    const st = this.searchTerm.toLowerCase();
    return (this.items.filter(f =>
      (this.selectedForm ? f.path.startsWith(`${formPath(this.selectedForm)}.`) : true) &&
      (
        (f.label?.toLowerCase().indexOf(st) >= 0) ||
        (f.key?.toLowerCase().indexOf(st) >= 0)
      )
    ));
  }

  selectForm(form: Form) {
    this.selectedForm = form;
    this.selectedHash = {};
  }

  toggleSelectAll(checked: boolean) {
    if (!this.selectedForm)
      return;

    this.selectedHash = {};

    if (checked) {
      this.selectedHash[formPath(this.selectedForm, null, true)] = true;
    }
  }

  isSelected(field: FormFieldRestriction) {
    const selected = (
      (this.existingHash && (this.existingHash[field.path] || this.existingHash[formPath(field.form, null, true)]))
      || (this.selectedForm && this.selectedHash[formPath(this.selectedForm, null, true)])
    );
    return selected;
  }

  ensureField(checked: boolean, path: string) {
    if (!checked) {
      delete this.selectedHash[path];

      const paths = keys(this.selectedHash);
      if (!paths || paths.length === 0) {
        this.selectedHash = {};
      }
    }
  }

  done() {
    const paths = keys(this.selectedHash);
    const restriction = new ApplicationRestriction;
    restriction.Type = this.type;
    restriction.Paths = paths;
    this.ceb.dispatch("done", [restriction]);
  }

  getFieldDescription(field: FormFieldRestriction) {
    return field.label?.trim().length > 0 ? field.label : field.key;
  }

  get ffUseQuestionLabelOnRestrictionsEnabled(): boolean {
    return this.ffs.isFeatureFlagEnabled("UseQuestionLabelOnRestrictions");
  }
}
