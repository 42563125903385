import { inject, needs, makeVueComponent } from "@derekpitt/fw";
import { cloneOf } from "fw-model";
import { DialogController } from "fw-dialog";
import { FormAnswer, Form, FormSection, FileHash, FileService, CeebRequest, DecryptData } from "../models";
import { validateAll, isAnswered } from "../validation";

type Args = {
  isNew: boolean;
  answers: FormAnswer[];
  form: Form;
  component: any;
  section: FormSection;
  baseAnswers: FormAnswer[];
  questionOptions: any;
  restrictions: string[];
};

@inject
export class AddTableSectionRow {
  private component = null; // to get around a circular reference :(
  private form: Form = null;
  private section: FormSection = null;
  private answers: FormAnswer[] = [];
  private baseAnswers: FormAnswer[] = [];
  private seenQuestionKeys: string[] = [];

  private readonly: boolean;
  private fileHash: FileHash;
  private fileService: FileService;
  private requestCeeb: CeebRequest;
  private decryptData: DecryptData;
  private displayonly: boolean;
  private showValidation: boolean;
  private showOptions: boolean;
  private isNew = true;
  private isInvalid = false;
  private restrictions: string[];

  constructor(private controller: DialogController<FormAnswer[]>) {}

  activate(params: Args) {
    this.component = makeVueComponent(params.component);
    this.section = params.section;
    this.answers = (params.answers || []).filter(a => a !== undefined).map(a => cloneOf(FormAnswer, a));
    this.baseAnswers = params.baseAnswers || [];
    this.isNew = !!params.isNew;
    this.restrictions=params.restrictions;

    this.readonly = params.questionOptions.readonly;
    this.fileHash = params.questionOptions.fileHash;
    this.fileService = params.questionOptions.fileService;
    this.requestCeeb = params.questionOptions.requestCeeb;
    this.decryptData = params.questionOptions.decryptData;
    this.displayonly = params.questionOptions.displayonly;
    this.showValidation = params.questionOptions.showValidation;
    this.showOptions = params.questionOptions.showOptions;

    this.form = params.form;
  }

  ok() {
    if (!validateAll(this.form, this.answers)) {
      this.showValidation = true;
      this.isInvalid = true;
      return;
    }

    this.isInvalid = false;

    this.controller.ok(this.answers);
  }

  cancel() {
    this.controller.cancel();
  }
}
