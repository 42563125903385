import type { ConditionFormType } from "forms/condition-info";
import { prop, inject, ComponentEventBus } from "fw";
import { nameof } from "helpers/nameof";
import { uniqBy } from "lodash-es";
import { DataDictionaryField, DataDictionaryFieldDataSource, SystemFieldType } from "models/data-dictionary";
import { Condition, ConditionFieldType, ConditionOperator, QuestionType } from "models/form";
import { PopoverService } from "service/popover";
import { conditionOperatorsNoValueRequired, getConditionOperators } from "shared/form-runtime";
import { OptionChooserPopover } from "views/reports/charts/option-chooser-popover";
import { IDropdownFieldOptions } from "./dropdown-field";
import { CategoryOptionChoice, OptionChoice } from "./option-chooser-with-categories";
import { Category, filterCategoriesByType, PathType, toCategory, toPathType } from "./path-chooser";
import { CustomFieldType } from "models/contact-organization";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { fieldTypes } from "forms/calculated-field";
import { FeatureFlagService } from "service/feature-flag";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";

@inject
export class ConditionBuilderCondition {
  @prop(null) public fields: DataDictionaryField[];
  @prop(null) public condition: ConditionFormType;
  @prop(false) public disabled: boolean;
  @prop(true) public canDelete: boolean;

  public conditionFieldType = ConditionFieldType;

  public fieldsHash: Map<string, DataDictionaryField> = null;

  constructor(
    private popover: PopoverService,
    private ceb: ComponentEventBus,
    private featureFlagService: FeatureFlagService
  ) {}

  public attached() {
    this.fieldsChanged();
  }

  public deleteSelf() {
    this.ceb.dispatch("delete-condition");
  }

  public useExpression() {
    this.ceb.dispatch("use-expression");
  }

  public get operatorOptions(): IDropdownFieldOptions[] {
    const type: ConditionFieldType = this.condition?.FieldType;
    const operators = getConditionOperators(type).map((op) => this.getOperatorOption(op));
    return operators;
  }

  public get currentOperatorText(): string {
    return this.getOperatorOption(this.condition.Operator).text;
  }

  private getOperatorOption(operator: ConditionOperator): IDropdownFieldOptions {
    const type: ConditionFieldType = this.condition?.FieldType;
    let text: string = operator;

    switch (operator) {
      case ConditionOperator.Equals:
        text = "equals";
        break;
      case ConditionOperator.GreaterThan:
        text = "greater than";
        break;
      case ConditionOperator.LessThan:
        text = "less than";
        break;
      case ConditionOperator.GreaterThanEquals:
        text = "greater than or equal to";
        break;
      case ConditionOperator.LessThanEquals:
        text = "less than or equal to";
        break;
      case ConditionOperator.NotEquals:
        text = "does not equal";
        break;
      case ConditionOperator.Contains:
        text = type === ConditionFieldType.Array ? "includes" : "contains";
        break;
      case ConditionOperator.NotContains:
        text = type === ConditionFieldType.Array ? "does not include" : "does not contain";
        break;
      case ConditionOperator.Empty:
        text = "is empty";
        break;
      case ConditionOperator.NotEmpty:
        text = "is not empty";
        break;
      case ConditionOperator.StartsWith:
        text = "starts with";
        break;
      case ConditionOperator.NotStartWith:
        text = "does not start with";
        break;
    }

    return <IDropdownFieldOptions>{
      text,
      value: operator,
    };
  }

  public get leftFieldName() {
    return this.fieldsHash.get(this.condition.Field)?.Label ?? this.condition.Field;
  }

  public get rightFieldName() {
    if (!this.condition.ValueIsField) return null;

    return this.fieldsHash.get(this.condition.Value as string)?.Label ?? this.condition.Value;
  }

  public get currentRightFieldValue() {
    if (this.condition.ValueIsField) {
      return this.rightFieldName;
    }

    return this.valueOptions.find((o) => o.value === this.condition.Value)?.text;
  }

  public async chooseField() {
    const uniqueFields = uniqBy(this.fields, nameof<DataDictionaryField>("Path"));
    const pathTypes: PathType[] = ["Boolean", "Number", "String"];
    if (this.featureFlagService.isFeatureFlagEnabled("AllowEnumerationTypeFieldsInConditionBuilder")) {
      pathTypes.push("Enumeration");
    }
    pathTypes.push("Object", "Date", "Array", "Tags", "File");

    const categories: Category[] = filterCategoriesByType(toCategory(uniqueFields), pathTypes);
    const options: CategoryOptionChoice<string>[] = [];

    for (const category of categories) {
      options.push({
        category: category.category,
        options: category.paths.map((p) => {
          return <{ text: string; value: string }>{ text: p.Label, value: p.Path };
        }),
      });
    }

    const result = await this.popover.open<OptionChoice<string>>(OptionChooserPopover, options);
    if (result.canceled) {
      return;
    }

    const field = this.getField(result.result.value);
    const fieldPath = field.ExportPath || field.Path;
    if (fieldPath !== this.condition.Field) {
      this.condition.ValueIsField = false;
      this.condition.Value = null;
    }
    this.condition.Field = fieldPath;
    this.condition.FieldType = this.getFieldType(field);
    const operators = getConditionOperators(this.condition.FieldType);
    if (!operators.includes(this.condition.Operator)) {
      this.condition.Operator = operators[0];
    }

    if (this.useValueOptions) {
      this.condition.Value = this.valueOptions[0]?.value;
    }
    this.onChanged();
  }

  public async chooseOperator() {
    const result = await this.popover.open<OptionChoice<string>>(OptionChooserPopover, this.operatorOptions);
    if (result.canceled) {
      return;
    }

    const operators = getConditionOperators(this.condition.FieldType);
    const operator = operators.find((o) => o === result.result.value);
    this.condition.Operator = operator;
    this.onOperatorChange();
  }

  public async chooseValue() {
    const result = await this.popover.open<OptionChoice<string>>(OptionChooserPopover, this.valueOptions);
    if (result.canceled) {
      return;
    }

    this.condition.Value = result.result.value;
    this.condition.ValueIsField = false;
    this.onChanged();
  }

  public get showValue(): boolean {
    return !conditionOperatorsNoValueRequired.includes(this.condition.Operator);
  }

  public get useValueOptions(): boolean {
    const field = this.fieldsHash.get(this.condition.Field);

    switch (this.condition.Operator) {
      case ConditionOperator.Equals:
      case ConditionOperator.NotEquals: {
        switch (field?.DataSource) {
          case DataDictionaryFieldDataSource.QuestionTypeCode:
            return [QuestionType.RadioButton, QuestionType.DropDown, QuestionType.Scale]
              .includes(field?.DataType);
          case DataDictionaryFieldDataSource.SystemFieldType:
            return false;
          case DataDictionaryFieldDataSource.ContactsFieldType:
            return [CustomFieldType.dropdown, CustomFieldType.boolean, CustomFieldType.funnel]
              .includes(field?.DataType);
          case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
            return field?.DataType === CalculatedFieldDataTypeCode.Enumeration
              || (field?.DataType === CalculatedFieldDataTypeCode.Boolean && field?.JsonDataType === "boolean");
        }
      }

      case ConditionOperator.Contains:
      case ConditionOperator.NotContains: {
        switch (field?.DataSource) {
          case DataDictionaryFieldDataSource.QuestionTypeCode:
            return field?.DataType === QuestionType.CheckBoxList
          case DataDictionaryFieldDataSource.SystemFieldType:
            return field?.DataType === SystemFieldType.Tags;
          case DataDictionaryFieldDataSource.ContactsFieldType:
            return [CustomFieldType.tags, CustomFieldType.multiselect]
              .includes(field?.DataType);
          case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
            return false;
        }
      }

      default:
        return false;
    }
  }

  public get valueOptions(): IDropdownFieldOptions[] {
    const field = this.fieldsHash.get(this.condition.Field);
    if (
      field?.DataType === CustomFieldType.boolean ||
      (field?.DataType === CalculatedFieldDataTypeCode.Boolean && field?.JsonDataType === "boolean")
    ) {
      return [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ];
    }

    const options = (field?.Values || [])
      .filter((o) => o !== null)
      .map((o) => {
        return typeof o === "string" 
          ? <IDropdownFieldOptions>{
              text: o,
              value: o,
            }
          : <IDropdownFieldOptions>o;
      });

    return options;
  }

  public async chooseFieldRight() {
    const pathTypes: PathType[] =
      this.condition.FieldType === ConditionFieldType.Date ? ["Date"] : ["Boolean", "Number", "String"];
    const categories: Category[] = filterCategoriesByType(toCategory(this.fields), pathTypes);
    const options: CategoryOptionChoice<string>[] = [];

    for (const category of categories) {
      options.push({
        category: category.category,
        options: category.paths.map((p) => {
          return <{ text: string; value: string }>{ text: p.Label, value: p.Path };
        }),
      });
    }

    const result = await this.popover.open<OptionChoice<string>>(OptionChooserPopover, options);
    if (result.canceled) {
      return;
    }
    const field = this.getField(result.result.value);
    this.condition.Value = field.ExportPath || field.Path;
    this.condition.ValueIsField = true;
    this.onChanged();
  }

  public deleteValue() {
    this.condition.Value = null;
    this.condition.ValueIsField = false;
    this.onChanged();
  }

  private getField(path: string): DataDictionaryField {
    return this.fields.find((f) => f.Path === path || f.ExportPath === path);
  }

  private getFieldType(field: DataDictionaryField): ConditionFieldType {
    if (field.DataType === CustomFieldType.user && this.fields.find((x) => x.ParentPath === field.Path)) {
      // if there are subfields of user, then user field is not just an id
      return ConditionFieldType.Object;
    }

    const isQuestionTypePhoneNumber =
      field.DataSource === DataDictionaryFieldDataSource.QuestionTypeCode &&
      field.DataType === QuestionType.PhoneNumber;
    const isContactsFieldTypePhone =
      field.DataSource === DataDictionaryFieldDataSource.ContactsFieldType &&
      field.DataType === CustomFieldType.phone;

    if (isQuestionTypePhoneNumber || isContactsFieldTypePhone) {
      return ConditionFieldType.Phone;
    }

    switch (toPathType(field)) {
      case "Boolean":
        return ConditionFieldType.Boolean;
      case "Date":
        return ConditionFieldType.Date;
      case "Number":
        return ConditionFieldType.Number;
      case "String":
      case "Enumeration":
        return ConditionFieldType.String;
      case "Tags":
      case "Array":
        return ConditionFieldType.Array;
      case "Object":
      case "File":
        return ConditionFieldType.Object;
      default:
        throw new Error("Unsupported field type");
    }
  }

  public get validation(): string {
    return this.condition.validation[nameof<Condition>("Field")];
  }

  public onOperatorChange() {
    if (conditionOperatorsNoValueRequired.includes(this.condition.Operator)) {
      this.condition.Value = null;
    } else if (this.useValueOptions) {
      this.condition.Value = this.valueOptions[0]?.value;
    }

    this.onChanged();
  }

  private fieldsChanged() {
    const newHash = new Map<string, DataDictionaryField>();
    for (const field of this.fields || []) {
      newHash.set(field.ExportPath || field.Path, field);
    }

    this.fieldsHash = newHash;
  }

  public onChanged() {
    this.ceb.dispatch("change");
  }
}
