import { inject, Navigator, needs } from "fw";
import { dispatch } from "fw-state";

import { LogoutAction } from "state/actions";
import { PasswordStrengthMeter } from "views/components/password-strength-meter";
import { CurrentOrganizationStore } from "state/current-organization";
import { AppRepository } from "network/app-repository";
import { InviteContext } from "models/portal-invitation";
import { LoginForm as TheLoginForm } from "forms/login";
import { UserRepository } from "network/user-repository";
import { newPasswordFormCreator } from "forms/accept-invite";

import { LoginForm } from "../login/login-form";

@inject
@needs(PasswordStrengthMeter, LoginForm)
export class AcceptInvite {
  error = false;
  working = false;
  loading = false;
  token: string = null;
  inviteContext: InviteContext = null;
  createPasswordForm = newPasswordFormCreator(null);

  showTitles = true;

  constructor(
    private nav: Navigator,
    private orgStore: CurrentOrganizationStore,
    private appRepo: AppRepository,
    private userRepo: UserRepository,
  ) {}

  async activate(params) {
    dispatch(new LogoutAction());

    this.token = params.token;

    try {
      this.loading = true;
      this.inviteContext = await this.appRepo.inviteContext(this.token);

      if (this.portalContext != null) {
        const {
          OrganizationPortal: { AllowLocalLogin },
          IdentityProviders,
        } = this.portalContext;
        
        // let's do some sso checking...
        if (AllowLocalLogin == false && IdentityProviders.length == 1) {
          this.ssoSignIn(IdentityProviders[0].Id);
          return;
        }
      }

      this.loading = false;
    } catch {
      this.error = true;
    }
  }

  get portalContext() {
    return this.orgStore.state.portalContext;
  }

  get orgName() {
    return this.inviteContext.OrganizationName;
  }

  ssoSignIn(providerId: string) {
    window.location.href = `https://${this.portalContext.OrganizationPortal.Domain}/auth/${providerId}/signin?domain=${encodeURIComponent(
      this.portalContext.OrganizationPortal.Domain,
    )}&token=${encodeURIComponent(this.token)}`;
  }

  get shouldSignIn() {
    if (this.inviteContext == null) return false;

    return !this.inviteContext.NeedsLocalPassword;
  }

  async createPassword() {
    this.working = true;

    try {
      this.createPasswordForm.validate();

      await this.userRepo.resetPassword(this.createPasswordForm.Password, this.token);
      const loginForm = new TheLoginForm(this.token, this.portalContext != null ? this.portalContext.OrganizationId : null);
      loginForm.EmailAddress = this.inviteContext.EmailAddress;
      loginForm.Password = this.createPasswordForm.Password;
      await dispatch(loginForm);

      this.loggedIn();
    } catch {}

    this.working = false;
  }

  loggedIn() {
    this.nav.navigate("/");
  }
}
