import { Validators, FormForType, formFor } from "fw-model";
import { CalculatedFieldFormCreator } from "./calculated-field";
const { isInteger, inRange, wrap } = Validators;
import { isValidJavascript, isValidKey } from "./validators";

import {
  Form,
  FormTypeCode,
  FormSection,
  Question,
  QuestionOptions,
  QuestionTableOptions,
  QuestionScaleOptions,
  QuestionScaleGroupOptions,
  FormQuestionScaleGroupItem,
  QuestionNumberOptions,
  QuestionCeebOptions,
  AnswerOption,
  FormQuestionScaleValue,
  TableColumn,
  TableSectionOptions,
  TableSectionColumn,
  TableSectionRow,
  TableSectionColumnSort,
  TableSectionColumnGroup,
} from "models/form";
import { conditionInfoFormCreator } from "./condition-info";

export type AddFormFormType = FormForType<Form>;
export type EditFormFormType = FormForType<Form>;
export type QuestionFormType = FormForType<Question>;
export type FormSectionFormType = FormForType<FormSection>;
export type TableSectionRowFormType = FormForType<TableSectionRow>;

export class AddFormAction {
  newForm: Form;

  constructor(public form: AddFormFormType, public formType: FormTypeCode, public contactType: string = null) { }
}

export class EditFormAction {
  constructor(public form: EditFormFormType, public version: string = null) { }
}

export class RefreshFormAction {
  constructor(public id: string) { }
}

export const AddFormForm = formFor(Form, s => {
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.Key, "Key", wrap(isValidKey));
});

export const editFormFormCreator = formFor(Form, s => {
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.Key, "Key", wrap(isValidKey));
  s.formArray(f => f.Sections, "Sections", FormSectionForm);
  s.formArray(
    f => f.CalculatedFields,
    "Calculated Fields",
    CalculatedFieldFormCreator,
  );
});

export const tableSectionColumnFormCreator = formFor(TableSectionColumn, s => {
  s.requiredField(a => a.FieldPath, "Path");
  s.requiredField(a => a.Label, "Label");
  s.requiredField(a => a.Width, "Width");
});

export const tableSectionColumnSortFormCreator = formFor(TableSectionColumnSort, s => {
  s.field(a => a.ColumnPath, "Path");
  s.field(a => a.Ascending, "Sort Order");
});

export const tableSectionColumnGroupFormCreator = formFor(TableSectionColumnGroup, s => {
  s.field(a => a.ColumnPath, "Path");
  s.field(a => a.Ascending, "Sort Order");
});

export const tableSectionOptionsFormCreator = formFor(TableSectionOptions, s => {
  s.requiredField(f => f.Key, "Key");
  s.field(f => f.MaxRowCount, "Max Row Count");
  s.field(f => f.MinRowCount, "Min Row Count");
  s.formArray(f => f.NamedRows, "Named Rows", tableSectionRowFormCreator);
  s.formArray(f => f.Columns, "Columns", tableSectionColumnFormCreator);
  s.formArray(f => f.Sort, "Sort", tableSectionColumnSortFormCreator);
  s.form(f => f.GroupBy, "Group By", tableSectionColumnGroupFormCreator);
});

export const FormSectionForm = formFor(FormSection, s => {
  s.field(f => f.Title, "Section Title");
  s.field(f => f.Introduction, "Introduction");
  s.field(f => f.Condition, "Condition");
  s.form(f => f.ConditionInfo, "Condition Builder", conditionInfoFormCreator);
  s.formArray(f => f.Questions, "Questions", QuestionForm);

  s.field(f => f.IsTableSection, "Is Table Section");
  s.form(f => f.TableSectionOptions, "Options", tableSectionOptionsFormCreator);
});

export const QuestionForm = formFor(Question, s => {
  s.requiredField(f => f.Text, "Question");
  s.field(f => f.Label, "Label");
  s.field(f => f.Help, "Help Text");
  s.field(f => f.IsRequired, "Required");
  s.field(f => f.IsIndexed, "Indexed");
  s.requiredField(f => f.Key, "Key", wrap(isValidKey));
  s.requiredField(f => f.Type, "Question Type");
  s.field(f => f.Condition, "Condition", wrap(isValidJavascript));
  s.form(f => f.ConditionInfo, "Condition Builder", conditionInfoFormCreator);
  s.field(f => f.ContactFieldKey, "Contact Field");
  s.form(f => f.Options, "Question Options", QuestionOptionsForm);
  s.formArray(f => f.AnswerOptions, "Answer Options", AnswerOptionForm);
});

export const QuestionOptionsForm = formFor(QuestionOptions, s => {
  s.field(f => f.MaxLength, "Max Length", wrap(isInteger, inRange(1, 10000)));
  s.field(f => f.AllowWriteIn, "Allow Write In");
  s.form(f => f.Table, "Table Options", QuestionTableOptionsForm);
  s.form(f => f.Scale, "Scale Options", QuestionScaleOptionsForm);
  s.form(
    f => f.ScaleGroup,
    "Scale Group Options",
    QuestionScaleGroupOptionsForm,
  );
  s.form(f => f.Number, "Number Options", QuestionNumberOptionsForm);
  s.form(f => f.Ceeb, "Ceeb Options", CeebOptionsForm);
});

export const tableColumnFormCreator = formFor(TableColumn, s => {
  s.requiredField(s => s.Label, "Column Name");
  s.requiredField(s => s.Width, "Width");
});

export const QuestionTableOptionsForm = formFor(QuestionTableOptions, s => {
  s.field(f => f.MinRowCount, "Min Row Count");
  s.field(f => f.MaxRowCount, "Max Row Count");
  s.field(f => f.RowNames, "Row Names");
  s.formArray(f => f.Columns, "Columns", tableColumnFormCreator);
});

export const QuestionScaleOptionsForm = formFor(QuestionScaleOptions, s => {
  s.field(f => f.Mode, "Mode");
  s.formArray(f => f.Values, "Scale Values", ScaleValueForm);
});

export const QuestionScaleGroupOptionsForm = formFor(
  QuestionScaleGroupOptions,
  s => {
    s.field(f => f.Mode, "Mode");
    s.formArray(f => f.Values, "Scale Values", ScaleValueForm);
    s.formArray(f => f.Items, "Scale Items", ScaleGroupItemForm);
  },
);

export const QuestionNumberOptionsForm = formFor(QuestionNumberOptions, s => {
  s.field(f => f.Type, "Type");
});

export const CeebOptionsForm = formFor(QuestionCeebOptions, s => {
  s.field(f => f.FileType, "Type");
});

export const ScaleValueForm = formFor(FormQuestionScaleValue, s => {
  s.requiredField(f => f.Label, "Label");
  s.field(f => f.Value, "Value");
});

export const tableSectionRowFormCreator = formFor(TableSectionRow, s => {
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.Key, "Key", wrap(isValidKey));
});

export const ScaleGroupItemForm = formFor(FormQuestionScaleGroupItem, s => {
  s.requiredField(f => f.Label, "Label");
  s.requiredField(f => f.Key, "Key", wrap(isValidKey));
});

export const AnswerOptionForm = formFor(AnswerOption, s => {
  s.field(f => f.Id, "Answer Id");
  s.field(f => f.Label, "Answer Option");
  s.field(f => f.Condition, "Condition", wrap(isValidJavascript));
  s.form(f => f.ConditionInfo, "Condition Builder", conditionInfoFormCreator);
});
