import { inject } from "fw";
import { createFromArray } from "fw-model";

import { ATS } from "./ats";
import { ApplicationRestriction } from "models/role";

@inject
export class GlobalDataRestrictionsRepository {
  public constructor(private s: ATS) {}

  public async putApplicationRestrictions(applicationRestriction: ApplicationRestriction[], force: boolean = false): Promise<ApplicationRestriction[]> {
    const res = await this.s.put<ApplicationRestriction[]>(`global-data-restrictions/application-restrictions?force=${force}`, applicationRestriction);
    return createFromArray(ApplicationRestriction, res.body);
  }

  public async getApplicationRestrictions(seasonId: string): Promise<ApplicationRestriction[]> {
    const res = await this.s.get<ApplicationRestriction[]>(`global-data-restrictions/application-restrictions/${seasonId}`);
    return createFromArray(ApplicationRestriction, res.body);
  }
}
