import { fromClass, fromClassArray, createFrom, createFromArray, makerOf } from "fw-model";
import {
  CEEBSourceFileType,
  CalculatedFieldDataTypeCode,
  FormTypeCode,
  NumberQuestionType,
  QuestionScaleMode,
  QuestionType
} from "./enums";

export class ConditionInfo {
  ReferencedFields: string[] = [];
  Conditions: ConditionGroup;
}

export type ICondition = Condition | ConditionGroup | ConditionExpression;

export class ConditionGroup {
  Operator: ConditionGroupOperator = ConditionGroupOperator.And;
  Conditions: ICondition[] = [];
}

export class Condition {
  Field: string = null;
  FieldType: ConditionFieldType = ConditionFieldType.String;
  Operator: ConditionOperator = ConditionOperator.Equals;
  Value: boolean | number | string | Date;
  ValueIsField: boolean = false;
}

export class ConditionExpression {
  Expression: string = null;
}


export enum ConditionGroupOperator {
  And = "And",
  Or = "Or"
}

export enum ConditionOperator {
  Equals = "Equals",
  NotEquals = "NotEquals",
  GreaterThan = "GreaterThan",
  GreaterThanEquals = "GreaterThanEquals",
  LessThan = "LessThan",
  LessThanEquals = "LessThanEquals",
  Contains = "Contains",
  NotContains = "NotContains",
  Empty = "Empty",
  NotEmpty = "NotEmpty",
  StartsWith = "StartsWith",
  NotStartWith = "NotStartsWith"
}

export enum ConditionFieldType {
  Boolean = "Boolean",
  Date = "Date",
  Number = "Number",
  String = "String",
  Object = "Object",
  Array = "Array",
  Phone = "Phone"
}

export class CalculatedField {
  Label: string = null;
  Key: string = null;
  IsKeyEdited?: boolean = false;
  Expression: string = null;
  @fromClass BooleanExpressionInfo: ConditionInfo;
  DataType = CalculatedFieldDataTypeCode.String;
}

export class TableColumn {
  Label: string = null;
  Width: number = null;
}

export class QuestionTableOptions {
  @fromClassArray(TableColumn) Columns: TableColumn[];

  RowNames: string[] = [];

  MinRowCount: number = null;
  MaxRowCount: number = null;
}

export class FormQuestionScaleValue {
  Label: string = null;
  Value: number = null;
}

export class FormQuestionScaleGroupItem {
  Label: string = null;
  Key: string = null;
  IsKeyEdited?: boolean = false;
}

export class QuestionScaleOptions {
  Mode = QuestionScaleMode.Range;

  @fromClassArray(FormQuestionScaleValue) Values: FormQuestionScaleValue[]
}

export class QuestionScaleGroupOptions {
  Mode = QuestionScaleMode.Range;

  @fromClassArray(FormQuestionScaleValue) Values: FormQuestionScaleValue[]
  @fromClassArray(FormQuestionScaleGroupItem) Items: FormQuestionScaleGroupItem[]
}

export class QuestionNumberOptions {
  Type = NumberQuestionType.Integer;
}

export class QuestionCeebOptions {
  FileType = CEEBSourceFileType.College;
}

export class QuestionOptions {
  MaxLength: number = null;
  AllowWriteIn: boolean = null;

  @fromClass Table: QuestionTableOptions;
  @fromClass Scale: QuestionScaleOptions;
  @fromClass ScaleGroup: QuestionScaleGroupOptions;
  @fromClass Number: QuestionNumberOptions;
  @fromClass Ceeb: QuestionCeebOptions;
}

export class AnswerOption {
  Id: string = null;
  Label: string = null;
  Condition?: string = null;
  @fromClass ConditionInfo?: ConditionInfo;
}

export class Question {
  Key: string = null;
  Label: string = null;
  Text: string = null;
  Help: string = null;
  IsRequired = false;
  IsIndexed = false;
  Type = QuestionType.ShortText;
  Condition: string = null;
  @fromClass ConditionInfo: ConditionInfo;
  ContactFieldKey: string = null;

  @fromClass Options: QuestionOptions;
  @fromClassArray(AnswerOption) AnswerOptions: AnswerOption[];
}

export class FormMetaData {
  OrganizationId: string;
  SeasonId: string;
  Type: FormTypeCode;
  HasActiveVersionBeenOpened: boolean;
  ContactType: string;
  FormVersionNumber: number;
  Version: string;
  IsManaged: boolean;
}

export class TableSectionRow {
  Key: string = null;
  Name: string = null;
}

export class TableSectionColumn {
  FieldPath: string = null;
  Label: string = null;
  Width: number = null;
}

export class TableSectionColumnSort {
  ColumnPath: string = null;
  Ascending: boolean = true;
}

export class TableSectionColumnGroup {
  ColumnPath: string = null;
  Ascending: boolean = true;
}

export class TableSectionOptions {
  Key: string = null;
  IsKeyEdited?: boolean = false;

  //@fromClassArray(CalculatedField) RowCalculatedFields: CalculatedField[];
  @fromClassArray(TableSectionRow) NamedRows: TableSectionRow[];
  @fromClassArray(TableSectionColumn) Columns: TableSectionColumn[];
  @fromClassArray(TableSectionColumnSort) Sort: TableSectionColumnSort[];
  @fromClass GroupBy: TableSectionColumnGroup;

  MinRowCount = 0;
  MaxRowCount = 10;
}

export class FormSection {
  Title: string = null;
  Introduction: string = null;
  Condition: string = null;
  IsTableSection = false;
  IsNew?: boolean = false;

  @fromClass ConditionInfo: ConditionInfo;
  @fromClassArray(Question) Questions: Question[];
  @fromClass TableSectionOptions: TableSectionOptions;
}

export class Form {
  Id: string;
  Name: string = null;
  Key: string = null;
  @fromClass MetaData: FormMetaData;

  @fromClassArray(FormSection) Sections: FormSection[];
  @fromClassArray(CalculatedField) CalculatedFields: CalculatedField[];
}

export class ValidationItem {
  Path: string;
  Message: string;
}

export interface MetaDataValidation {
  IsValid: boolean;
  ValidationSummary: ValidationItem[];
}

export class FormAnswerMetaData implements MetaDataValidation {
  IsValid: boolean = null;
  @fromClassArray(ValidationItem) ValidationSummary: ValidationItem[] = [];
}

export class TableSectionRowFormData {
  RowKey: string = null;
  Answers: FormAnswer[] = [];

  // TODO: calc fields
}

export class FormAnswer {
  SectionKey: string = null;
  QuestionKey: string = null;
  Text: string = null;
  Number: number = null;
  PhoneNumber: string = null;
  FormAnswerOptions: { AnswerOptionId?: string; OptionText: string, IsWriteIn?: boolean }[] = null;
  FormAnswerOption: { AnswerOptionId?: string; OptionText: string, IsWriteIn?: boolean } = null;
  Rows: string[][] = null;
  Data: { [key: string]: string } = null;
  Date: string = null;
  FileId: string = null;
  IsEncrypted = false;

  @fromClassArray(TableSectionRowFormData) SectionAnswers: TableSectionRowFormData[]

  @fromClass MetaData: FormAnswerMetaData = new FormAnswerMetaData();
}

export interface PendingFile {
  waitUntilUploaded(): Promise<any>;

  fileName: string;
}

export type FileHash = { [id: string]: any; };

export interface File {
  FileId: string;
  GetUrl: string;
}

export interface FileService {
  getFile(): Promise<{ file: File, pendingFile: PendingFile }>;

  enqueue(file);
}

export type CeebRequestResult = { code: string, name: string, address: string, city: string, region: string, country: string, postalCode: string };
export type CeebRequest = (question, element) => Promise<CeebRequestResult>;
export type DecryptData = (data: string) => Promise<string>;

export type ComputedCalculatedField = { FieldKey: string, Value: any };

export interface ComputedSection {
  sectionIndex: number;
  questions: ComputedQuestion[];
}

export interface ComputedQuestion {
  index: number;
  key: string;
  answerOptions?: ComputedAnswerOption[];
}

export interface ComputedAnswerOption {
  index: number;
  label: string;
}

export interface Resolved {
  sections: ComputedSection[];
  calculatedValues: ComputedCalculatedField[];
}

export type Result = {
  id: string;
  sections: ComputedSection[];
  calculatedValues: ComputedCalculatedField[];
  isRaw: boolean;
  rawResult?: any;
  error: any;
};

export interface IProgramPropertiesHash {
  [questionKey: string]: string | boolean;
}

/* need to explore this more...
export interface Opener {
  open<T>(component: makerOf<any>, params: any): Promise<{ canceled: boolean, result: T }>;
}
*/


