import { inject, NetworkException, Navigator } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { wait } from "wait";
import { Base64 } from "js-base64";
import { CurrentOrganizationStore } from "state/current-organization";

import { FormErrorHandling } from "./error-handling";
import {
  LogoutAction,
  StartAction,
  RefreshTokenAction,
  ApplyUserChangeAction,
  ImpersonateUserAction,
  StopImpersonatingAction,
  ReplaceTokenAction,
} from "./actions";

import { User } from "models/user";
import { UserRepository } from "network/user-repository";
import { AppRepository } from "network/app-repository";
import { ContactOrganizationRepository } from "network/contact-organization-repository";
import { UnauthorizedMiddleware } from "network/middleware/unauthorized-middleware";
import { FeatureFlagService } from "service/feature-flag";
import { LoginForm } from "forms/login";
import { AcceptInviteAction } from "forms/accept-invite";
import { UpdateMyEmailAddress, UpdateMyPassword } from "forms/update-my-account";
import { UpdateUserAction } from "forms/user";
import { ATS } from "network/ats";
import { Principal, PrincipalType } from "models/principal";
import { ContactOrganizationModelChangedAction } from "state/actions";
import {
  InitActivityTrackerAction,
  StopActivityTrackerAction,
  ToggleActivityAlert,
  ActivityStore,
} from "state/activity";
import { dateDiff } from "helpers/date-diff";
import { RoutePathStore } from "./route-path";
import { AccountType } from "models/organization";
import { ContactOrganization, RoleSettings } from "models/contact-organization";
import { clearInterval, setInterval } from "worker-timers";
import { PortalType } from "../models/app-organization-portal-context";
import { resetAllOnce } from "helpers/once";
import { parseJwt } from "../helpers/parse-jwt";

type TokenPayload = {
  "ats-type": string;
  "ats-pm": string[];
};

interface CurrentUserShape {
  organizationId: string;
  user: User;
  roleId: string;
  roleSettings: RoleSettings;
  portalIds: string[];
  hiddenContactTypes: string[];
  restrictedContactFields: { [contactType: string]: string };
  permissions: string[];
  lastPage?: string;
  lastSettingsPage?: string;
  workspaceIds?: string[];

  principal: Principal;
  loggedIn: boolean;
  impersonating: boolean;
  canSwitchOrgs: boolean;
  isGlobalPrincipal: boolean;
  isGlobalAdmin: boolean;
  logoutReason: string;
  isSwitchingOrganization?: boolean;
}

export class ActivateMfaAction {
  constructor(public secret: string, public code: string) {}
}

export class SwitchingOrganizationAction {
  constructor() {}
}

export class DisableMfaAction {
  constructor() {}
}

export class UpdateLastPageAction {
  constructor(public lastPage: string) {}
}

export class UpdateLastSettingsPageAction {
  constructor(public lastSettingsPage: string) {}
}

@inject
export class CurrentUserStore extends Store<CurrentUserShape> {
  private timerStartActivity = null;
  private timerCheckToken = null;
  private intervalTimer = null;

  constructor(
    private userRepo: UserRepository,
    private appRepo: AppRepository,
    private contactOrgRepo: ContactOrganizationRepository,
    private unauthMiddleware: UnauthorizedMiddleware,
    private featureFlagService: FeatureFlagService,
    private network: ATS,
    private activityStore: ActivityStore,
    private currentOrganizationStore: CurrentOrganizationStore,
    private nav: Navigator,
    private routePath: RoutePathStore
  ) {
    super();
  }

  public get cacheKeyPreferNewContactUi(): string {
    return `${this.state.organizationId}:${this.state.user.Id}:cacheKeyPreferNewContactUi`;
  }

  defaultState() {
    return {
      organizationId: null,
      user: null,
      roleId: null,
      roleSettings: null,
      portalIds: [],
      hiddenContactTypes: [],
      restrictedContactFields: null,
      workspaceIds: null,
      permissions: [],
      lastPage: null,
      lastSettingsPage: null,

      principal: null,
      loggedIn: false,
      impersonating: false,
      canSwitchOrgs: false,
      isGlobalPrincipal: false,
      isGlobalAdmin: false,
      logoutReason: null,
      isSwitchingOrganization: false,
    };
  }

  getPrincipalInfo() {
    const principalToken = this.network.hasImpersonationToken
      ? this.network.impersonationToken
      : this.network.token;

    const jwtParts = principalToken.split(".");
    if (jwtParts.length < 2) return null;

    try {
      const decoded = JSON.parse(Base64.decode(jwtParts[1])) as TokenPayload;

      const permissions: string[] = [];
      permissions.push(...(decoded["ats-pm"] || []));

      let principal: Principal = {
        Type: PrincipalType[decoded["ats-type"]],
        Id: decoded["ats-id"],
        EmailAddress: decoded["email"],
        FirstName: decoded["given_name"],
        LastName: decoded["family_name"],
      };

      return {
        roleId: decoded["ats-role-id"],
        roleSettings: null,
        portalIds: decoded["ats-portal-id"],
        hiddenContactTypes: [],
        restrictedContactFields: null,
        permissions: permissions,
        principal: principal,
        impersonating: this.network.hasImpersonationToken,
        isGlobalPrincipal:
          principal.Type == PrincipalType.GlobalAdmin || principal.Type == PrincipalType.GlobalSupport,
        isGlobalAdmin: principal.Type == PrincipalType.GlobalAdmin,
        loggedIn: principalToken?.length > 0 && decoded["ats-org"] !== null,
      };
    } catch {
      return null;
    }
  }

  loadUser(organizationId: string, user: User) {
    let principalInfo = this.getPrincipalInfo();

    this.unauthMiddleware.loggedIn = principalInfo.loggedIn;

    let canSwitchOrgs =
      !principalInfo.impersonating && (principalInfo.isGlobalPrincipal || user?.Memberships.length > 1);

    this.setState((state) => ({
      ...principalInfo,
      organizationId: organizationId,
      user: user,
      canSwitchOrgs: canSwitchOrgs,
      logoutReason: null,
    }));
  }

  loadHotjar() {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 1199921, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window as any, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }

  loadHeap(s: StartAction) {
    if (s.context.SystemConfig.HeapAppId == null) {
      console.log("Heap did not load because no HeapAppId is configured.");
      return;
    }

    const heap = ((window as any).heap = (window as any).heap || []);
    if (heap.loaded) {
      heap.resetIdentity();
      return;
    }

    heap.load = function (e, t) {
      (heap.appid = e), (heap.config = t = t || {});
      var r = document.createElement("script");
      (r.type = "text/javascript"),
        (r.async = !0),
        (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js");
      var a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(r, a);
      for (
        var n = function (e) {
            return function () {
              heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            "addEventProperties",
            "addUserProperties",
            "clearEventProperties",
            "identify",
            "resetIdentity",
            "removeEventProperty",
            "setEventProperties",
            "track",
            "unsetEventProperty",
          ],
          o = 0;
        o < p.length;
        o++
      ) {
        heap[p[o]] = n(p[o]);
      }
    };

    heap.load(s.context.SystemConfig.HeapAppId);
    heap.identify(this.state.principal.Id);

    const membership = this.state.user?.Memberships.find(
      (m) => m.OrganizationId == s.context.Organization.Id
    );
    const role = s.context.Roles.find((r) => r.Id == membership?.RoleId);

    heap.addUserProperties({
      name: this.state.principal.FirstName + " " + this.state.principal.LastName,
      email: this.state.principal.EmailAddress,
      ad_title: this.state.user?.Title,
      ad_address_state: this.state.user?.Address?.Region,
      ad_dept: this.state.user?.Department,
      ad_user_role: role?.Name,
      ad_user_permissions: role?.Permissions?.join(","),
      ad_user_type: PrincipalType[this.state.principal.Type],
      app_name: "Liaison Outcomes",

      ad_org_timezone: s.context.Organization.Timezone,
      ad_org_id: s.context.Organization.Id,
      ad_org_type: AccountType[s.context.Organization.MetaData.Type],
      ad_org_tags: s.context.Organization.Tags?.join(","),
      ad_org_salesforce_account_id: s.context.Organization.MetaData.SalesForceId,
      ad_org_created: s.context.Organization.MetaData.DateCreatedUtc,
      ad_org_name: s.context.Organization.Name,
    });
  }

  loadAdaCx(s: StartAction) {
    const script = document.createElement("script");
    script.id = "__ada";
    // TODO: Change when AdaCx data handle key will be added to SystemConfig
    script.dataset.handle = "demo-liaison-gr";

    script.src = "https://static.ada.support/embed2.js";

    document.body.appendChild(script);

    const membership = this.state.user?.Memberships.find(
      (m) => m.OrganizationId == s.context.Organization.Id
    );
    const role = s.context.Roles.find((r) => r.Id == membership?.RoleId);

    const metaData = {
      outcomes_org_id: s.context.Organization.Id,
      outcomes_org_name: s.context.Organization.Name,
      outcomes_user_email: this.state.principal.EmailAddress,
      outcomes_user_id: this.state.principal.Id,
      outcomes_user_name: `${this.state.principal.FirstName} ${this.state.principal.LastName}`,
      outcomes_user_role: role?.Name,
    };
    // @ts-ignore
    window.adaSettings = { metaFields: metaData };
  }

  loadChameleon(s: StartAction) {
    if (s.context.SystemConfig.ChameleonAccountToken == null) {
      console.log("Chameleon did not load because no ChameleonAccountToken is configured.");
      return;
    }

    (function (d, w, token) {
      var c = "chmln",
        m = "identify alias track clear set show on off custom help _data".split(" "),
        i = d.createElement("script");
      if ((w[c] || (w[c] = {}), !w[c].root)) {
        (w[c].accountToken = token), (w[c].location = w.location.href.toString()), (w[c].now = new Date());
        for (var s = 0; s < m.length; s++) {
          (function () {
            var t = (w[c][m[s] + "_a"] = []);
            w[c][m[s]] = function () {
              t.push(arguments);
            };
          })();
        }
        (i.src = "https://fast.trychameleon.com/messo/" + token + "/messo.min.js"),
          (i.async = !0),
          d.head.appendChild(i);
      }
    })(document, window, s.context.SystemConfig.ChameleonAccountToken);
  }

  async callChameleon(s: StartAction) {
    const chmln = (window as any).chmln;

    if (chmln == null) {
      console.log("Tried to call Chameleon before it was loaded.");
      return;
    }

    const chmlnElement = document.getElementById("chmln-dom");
    if (chmlnElement) chmlnElement.setAttribute("data-axe-ignore", "true");

    const membership = this.state.user?.Memberships.find(
      (m) => m.OrganizationId == s.context.Organization.Id
    );
    const role = s.context.Roles.find((r) => r.Id == membership?.RoleId);

    let chameleonProps = {
      name: this.state.principal.FirstName + " " + this.state.principal.LastName,
      // first_name: this.state.principal.FirstName,
      // last_name: this.state.principal.LastName,
      email: this.state.principal.EmailAddress,
      ad_title: this.state.user?.Title,
      ad_address_state: this.state.user?.Address?.Region,
      ad_dept: this.state.user?.Department,
      ad_user_role: role?.Name,
      ad_user_permissions: role?.Permissions,
      ad_user_type: PrincipalType[this.state.principal.Type],
      app_name: "Liaison Outcomes",

      company: {
        uid: s.context.Organization.Id,
        ad_org_timezone: s.context.Organization.Timezone,
        ad_org_type: AccountType[s.context.Organization.MetaData.Type],
        ad_org_tags: s.context.Organization.Tags,
        ad_org_salesforce_account_id: s.context.Organization.MetaData.SalesForceId,
        ad_org_created: s.context.Organization.MetaData.DateCreatedUtc,
        ad_org_name: s.context.Organization.Name,
      },
    };

    // TargetX is not yet using Secure Identity Verification, so sending this property will break their implementation.
    // const secureIdentity = await this.userRepo.getChameleonVerification();
    // if (secureIdentity != null) {
    //   chameleonProps["uid_hash"] = secureIdentity;
    // }

    chmln.identify(this.state.principal.Id, chameleonProps);
  }

  @handle(ReplaceTokenAction)
  private async handleReplaceToken(action: ReplaceTokenAction) {
    if (this.network.hasImpersonationToken) this.network.setImpersonationToken(action.token);
    else if (this.network.hasToken) this.network.setToken(action.token);

    this.loadUser(this.state.organizationId, this.state.user);
  }

  @handle(LoginForm, FormErrorHandling)
  private async handleLoginForm(loginForm: LoginForm) {
    loginForm.validate();

    const { EmailAddress, Password, organizationId, inviteToken, Code, redirectTo } = loginForm;

    const token = await this.userRepo.login({
      EmailAddress,
      Password,
      OrganizationId: organizationId,
      Token: inviteToken,
      Code,
      PortalId: this.currentOrganizationStore.state.portalContext?.OrganizationPortal.Id,
      PortalType: PortalType.Organization,
    });

    const parsedJWT = token && parseJwt(token);

    if (!parsedJWT["ats-org"]) {
      if (redirectTo && redirectTo.trim() !== "") {
        this.nav.navigate(`/choose-organization?redirect=${redirectTo}`);
      } else {
        this.nav.navigate("/choose-organization");
      }
      return;
    }

    if (inviteToken != null) {
      await wait(2000);
    }

    const context = await this.appRepo.organizationContext();
    //this.exceptionlessService.configure(context);

    if (context.Organization.Id != null) {
      context.ContactOrganization = await this.contactOrgRepo.getById(context.Organization.Id);
    }

    // reset the lazy load tracking for all states
    resetAllOnce();

    // signal app start
    await dispatch(new StartAction(context));
  }

  @handle(ImpersonateUserAction)
  private async handleImpersonateAction(action: ImpersonateUserAction) {
    const impersonationToken = await this.userRepo.impersonate(
      action.organizationUserId,
      action.portalId,
      action.isCurrentPortal
    );

    const context = await this.appRepo.organizationContext();
    //this.exceptionlessService.configure(context, true);

    if (context.Organization.Id != null) {
      context.ContactOrganization = await this.contactOrgRepo.getById(context.Organization.Id);
    }

    // reset the lazy load tracking for all states
    resetAllOnce();

    // signal app start
    await dispatch(new StartAction(context));
  }

  @handle(StopImpersonatingAction)
  private async handleStopImpersonatingAction(action: StopImpersonatingAction) {
    this.network.deleteImpersonationToken();
    const context = await this.appRepo.organizationContext();
    //this.exceptionlessService.configure(context, false);

    if (context.Organization.Id != null) {
      context.ContactOrganization = await this.contactOrgRepo.getById(context.Organization.Id);
    }

    // reset the lazy load tracking for all states
    resetAllOnce();

    // signal app start
    await dispatch(new StartAction(context));
  }

  private updateRoleSettings(contactOrganization: ContactOrganization) {
    if (this.state.roleId) {
      const roleSettings = contactOrganization?.roles?.find((r) => r.role_id === this.state.roleId);
      this.setState((state) => ({ ...state, roleSettings }));

      if (roleSettings) {
        const hiddenContactTypes = [];
        const restrictedContactFields = {};
        for (const dataPolicy of roleSettings.data_policies) {
          if (dataPolicy.is_hidden) hiddenContactTypes.push(dataPolicy.contact_type);
          if (dataPolicy.restricted_field_ids && dataPolicy.restricted_field_ids.length > 0)
            restrictedContactFields[dataPolicy.contact_type] = dataPolicy.restricted_field_ids;

          this.setState((state) => ({
            ...state,
            hiddenContactTypes,
            restrictedContactFields,
          }));
        }
      }
    }
  }

  @handle(ContactOrganizationModelChangedAction)
  private handleContactOrganizationModelChanged(action: ContactOrganizationModelChangedAction) {
    this.updateRoleSettings(action.organization);
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    this.loadUser(s.context.Organization.Id, s.context.Me);

    this.updateRoleSettings(s.context.ContactOrganization);

    this.setState((state) => ({
      ...state,
      workspaceIds: s.context.CurrentUserWorkspaceIds,
    }));

    this.loadHeap(s);

    if (this.featureFlagService.isFeatureFlagEnabled("ChameleonAnalytics")) {
      this.loadChameleon(s);
      this.callChameleon(s);
    }
    if (this.featureFlagService.isFeatureFlagEnabled("HeatmapAnalytics")) {
      this.loadHotjar();
    }

    if (this.featureFlagService.isFeatureFlagEnabled("AdaCxChat")) {
      this.loadAdaCx(s);
    }
    this.startIdleTracking();
  }

  @handle(LogoutAction)
  private handleLogout(action: LogoutAction) {
    setTimeout(() => {
      this.stopIdleTracking();

      this.userRepo.logout();
      this.unauthMiddleware.loggedIn = false;

      this.setState((state) => ({
        ...this.defaultState(),
        logoutReason: action.reason,
      }));
    }, 100);
  }

  @handle(UpdateUserAction)
  private async handleUpdateUser(u: UpdateUserAction) {
    u.form.validate();

    try {
      const user = await this.userRepo.put(u.form.updatedModel());
      this.loadUser(this.state.organizationId, user);
      dispatch(new ApplyUserChangeAction(user));
    } catch (err) {
      if (
        err instanceof NetworkException &&
        err.result.validation_result &&
        err.result.validation_result.length
      )
        u.form.validationMessages = err.result.validation_result.map((s) => s.message);
      u.form.isInvalid = true;
      throw err;
    }
  }

  @handle(UpdateMyPassword)
  private async handleUpdateMyPassword(u: UpdateMyPassword) {
    u.validate();

    try {
      const { CurrentPassword, NewPassword } = u;
      await this.userRepo.updatePassword(this.state.user.Id, {
        NewPassword,
        Password: CurrentPassword,
      });
    } catch (err) {
      if (err instanceof NetworkException) {
        if (
          err.statusCode == 400 &&
          err.result != null &&
          err.result.validation_result != null &&
          err.result.validation_result.some((s) => s.message.includes("Password strength"))
        ) {
          u.validation["NewPassword"] = "Password too weak";
        }
      } else {
        u.validationMessages = ["Could not update password"];
      }
      u.isInvalid = true;
      throw err;
    }
  }

  @handle(UpdateMyEmailAddress)
  private async handleUpdateMyEmailAddress(u: UpdateMyEmailAddress) {
    u.validate();

    try {
      const { CurrentPassword, NewEmailAddress } = u;
      await this.userRepo.updateEmail(this.state.user.Id, {
        OrganizationId: this.state.organizationId,
        EmailAddress: NewEmailAddress,
        Password: CurrentPassword,
        PortalType: PortalType.Organization,
      });
    } catch (err) {
      u.validationMessages = ["Could not update Email Address"];
      u.isInvalid = true;
      throw err;
    }
  }

  @handle(AcceptInviteAction, FormErrorHandling)
  private async handleAcceptInvite(i: AcceptInviteAction) {
    i.form.validate();

    try {
      await this.userRepo.post(i.form.updatedModel(), i.token);

      const lf = new LoginForm();
      lf.EmailAddress = i.form.EmailAddress;
      lf.Password = i.form.Password;

      await wait(2000);

      await dispatch(lf);
    } catch (err) {
      i.form.validationMessages = ["Could not accept invite"];
      throw err;
    }
  }

  @handle(ActivateMfaAction)
  private async handleActivateMfaAction(action: ActivateMfaAction) {
    await this.userRepo.activateMfa(this.state.user.Id, action.secret, action.code);
    this.state.user.MetaData.MfaEnabled = true;
    this.setState((s) => s);
  }

  @handle(DisableMfaAction)
  private async handleDisableMfaAction(action: DisableMfaAction) {
    await this.userRepo.disableMfa(this.state.user.Id);
    this.state.user.MetaData.MfaEnabled = false;
    this.setState((s) => s);
  }

  @handle(UpdateLastPageAction)
  private async handleUpdateLastPageAction(action: UpdateLastPageAction) {
    this.setState((state) => ({ ...state, lastPage: action.lastPage }));
  }

  @handle(UpdateLastSettingsPageAction)
  private async handleUpdateLastSettingsPageAction(action: UpdateLastSettingsPageAction) {
    this.setState((state) => ({
      ...state,
      lastSettingsPage: action.lastSettingsPage,
    }));
  }

  stopIdleTracking() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = null;
    }

    dispatch(new StopActivityTrackerAction());
  }

  private startIdleTracking() {
    const MINUTE = 1000 * 60;
    const RENEWAL_BUFFER = MINUTE * 2; // how long before token expiration do we renew/remind
    let ACTIVITY_IDLE_TIME = MINUTE * 9; // inactivity duration before considered idle
    // because the activity tracker uses setTimeout to measure idle time, laptop sleep does not count toward idle time

    if (this.network.tokenLifespan > 0) {
      // when session lifespan is overriden for testing, use a short idle time
      ACTIVITY_IDLE_TIME = MINUTE * 0.5;
    }

    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = null;
    }

    this.intervalTimer = setInterval(() => this.checkTimes(RENEWAL_BUFFER), 15000);

    dispatch(new InitActivityTrackerAction(ACTIVITY_IDLE_TIME));
  }

  private async checkTimes(renewalBuffer) {
    // get token expiration from local storage (shared with other tabs)
    const tokenExpDate = this.network.getTokenExpDate();
    if (!tokenExpDate) {
      await dispatch(new LogoutAction("You were automatically logged out."));
      this.nav.navigate("/login");
      return;
    }

    const timeLeft = dateDiff(new Date(), tokenExpDate);
    if (timeLeft < 0) {
      // we missed the renewal window, probably because of laptop sleep
      await dispatch(new LogoutAction("You were automatically logged out due to inactivity."));
      const fullRoute = this.routePath.state.currentPath ?? "/";
      this.nav.navigate("/login", { redirect: fullRoute });
    } else if (timeLeft > renewalBuffer) {
      // do nothing; token still has time left
      return;
    } else if (this.activityStore.state.showActivityAlert) {
      // do nothing; alert is already showing
      return;
    } else if (this.activityStore.state.isIdle) {
      // user is idle and token is about to expire; show the alert
      await dispatch(new ToggleActivityAlert(true));
    } else {
      // user is active and token is about to expire; renew it now
      await dispatch(new RefreshTokenAction());
    }
  }

  @handle(RefreshTokenAction)
  private async handleRefreshToken() {
    dispatch(new ToggleActivityAlert(false));
    await this.userRepo.refreshToken();
  }

  @handle(SwitchingOrganizationAction)
  private async handleSwitchingOrganizationAction() {
    this.setState((state) => ({ ...state, isSwitchingOrganization: !this.state.isSwitchingOrganization }));
  }
}
