import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { LocalStorageCache, SessionStorageCache } from "caching";

import { FormErrorHandling } from "./error-handling";

import { UserRepository } from "network/user-repository";
import { OrganizationRepository } from "network/organization-repository";
import { AppRepository } from "network/app-repository";
import { OrganizationPortalRepository } from "network/organization-portal-repository";
import { ContactOrganizationRepository } from "network/contact-organization-repository";

import { Organization, PaymentSettings, OrganizationPortal, Season } from "models/organization";

import { SystemConfig } from "models/system-config";

import { StartAction, LogoutAction, SwitchOrganizationAction } from "./actions";

import { PaymentSettingsRepository } from "network/payment-settings-repository";
import { UpdateOrganizationAction } from "forms/organization";
import { UpdateOrganizationPortalAction } from "forms/organization-portal";

import { SavePaymentGatewayAction, ActivatePaymentGatewayAction } from "forms/payment-gateway";
import { PaymentGateway } from "models/organization";
import { AppOrganizationPortalContext } from "models/app-organization-portal-context";
import { DocumentProvider } from "models/document-provider";
import { PathTranslation } from "models/app-organization-context";
import { defineDomainLogoUrl } from "helpers/logo";
import { resetAllOnce } from "helpers/once";
import { GlobalDataRestrictionsRepository } from "network/global-data-restrictions-repository";

export enum NavBarLabelType {
  DashboardLabel = "dashboardLabel",
  CurrentOrganizationShortNameLabel = "currentOrganizationShortNameLabel",
  ContactsLabel = "contactsLabel",
  ReportingLabel = "reportingLabel",
  ApplicationsLabel = "applicationsLabel",
  ConversationsLabel = "conversationsLabel",
  MarketingLabel = "marketingLabel",
  QuickMessagesLabel = "quickMessagesLabel",
  CampaignBuilderLabel = "campaignBuilderLabel",
  MarketingTemplatesLabel = "marketingTemplatesLabel",
  TasksLabel = "tasksLabel",
  CalendarLabel = "calendarLabel",
  ActivityLabel = "activityLabel",
}

export enum NavBarNames {
  Dashboard = "dashboard",
  Contacts = "contacts",
  Reports = "reports",
  Applications = "applications",
  Conversations = "conversations",
  Marketing = "marketing",
  Tasks = "tasks",
  Calendar = "calendar",
  Notifications = "notifications",
}

export const NAV_BAR_DEFAULT_LABELS: Record<NavBarLabelType, string> = {
  [NavBarLabelType.DashboardLabel]: "My Dashboard",
  [NavBarLabelType.CurrentOrganizationShortNameLabel]: "",
  [NavBarLabelType.ContactsLabel]: "Contacts",
  [NavBarLabelType.ReportingLabel]: "Reporting",
  [NavBarLabelType.ApplicationsLabel]: "Applications",
  [NavBarLabelType.ConversationsLabel]: "Conversations",
  [NavBarLabelType.MarketingLabel]: "Marketing",
  [NavBarLabelType.QuickMessagesLabel]: "Quick Messages",
  [NavBarLabelType.CampaignBuilderLabel]: "Campaign Builder",
  [NavBarLabelType.MarketingTemplatesLabel]: "Templates",
  [NavBarLabelType.TasksLabel]: "Tasks",
  [NavBarLabelType.CalendarLabel]: "Calendar",
  [NavBarLabelType.ActivityLabel]: "Activity",
};

interface CurrentOrganizationStoreShape {
  isLoading: boolean;
  portalContext: AppOrganizationPortalContext;
  organization: Organization;
  season: Season;
  paymentSettings: PaymentSettings;
  organizationPortal: OrganizationPortal;
  documentProviders: DocumentProvider[];
  systemConfig: SystemConfig;
  pathTranslations: PathTranslation[];
  customization: {
    contactsEnabled: boolean;
    contactsLabel: string;
    dashboardEnabled: boolean;
    dashboardLabel: string;
    currentOrganizationShortNameEnabled: boolean;
    currentOrganizationShortNameLabel: string;
    reportingEnabled: boolean;
    reportingLabel: string;
    activityEnabled: boolean;
    activityLabel: string;
    tasksEnabled: boolean;
    tasksLabel: string;
    conversationsEnabled: boolean;
    conversationsLabel: string;
    applicationsEnabled: boolean;
    applicationsLabel: string;
    marketingEnabled: boolean;
    marketingLabel: string;
    quickMessagesEnabled: boolean;
    quickMessagesLabel: string;
    campaignBuilderEnabled: boolean;
    campaignBuilderLabel: string;
    marketingTemplatesEnabled: boolean;
    marketingTemplatesLabel: string;
    calendarEnabled: boolean;
    calendarLabel: string;
    landingPage: NavBarNames | null;
    getNavLabel(labelType: string): string;
    authLogo?: string;
    reviewPortalVanityDomain: string;
    organizationShortName: string;
    helpCenterUrl: string;
  };
}

export class DeleteGatewayAction {
  constructor(public gatewayId: string) {}
}

export class SeedOrganizationPortalContextAction {
  constructor(public portalContext: AppOrganizationPortalContext) {}
}

export class SeedGlobalDataRestrictionsAction { }

export class SetIsLoading {
  constructor(public isLoading: boolean) {}
}

export class SetFeaturesAction {
  constructor(public moduleKeys: string[], public features: string[]) {}
}

@inject
export class CurrentOrganizationStore extends Store<CurrentOrganizationStoreShape> {
  constructor(
    private appRepo: AppRepository,
    private globalDataRestrictionsRepo: GlobalDataRestrictionsRepository,
    private cache: SessionStorageCache,
    private orgRepo: OrganizationRepository,
    private orgPortalRepo: OrganizationPortalRepository,
    private paymentSettingsRepo: PaymentSettingsRepository,
    private userRepo: UserRepository,
    private contactOrgRepo: ContactOrganizationRepository,
    private localStorageCache: LocalStorageCache
  ) {
    super();
  }

  defaultState() {
    return {
      portalContext: null,
      isLoading: true,
      organizationPortal: null,
      organization: null,
      season: null,
      paymentSettings: null,
      documentProviders: null,
      systemConfig: null,
      pathTranslations: [],
      notificationProfile: null,
      customization: {
        contactsEnabled: true,
        contactsLabel: null,
        dashboardEnabled: true,
        dashboardLabel: null,
        currentOrganizationShortNameEnabled: false,
        currentOrganizationShortNameLabel: null,
        reportingEnabled: true,
        reportingLabel: null,
        activityEnabled: true,
        activityLabel: null,
        tasksEnabled: true,
        tasksLabel: null,
        conversationsEnabled: true,
        conversationsLabel: null,
        applicationsEnabled: true,
        applicationsLabel: null,
        marketingEnabled: true,
        marketingLabel: null,
        quickMessagesEnabled: true,
        quickMessagesLabel: null,
        campaignBuilderEnabled: true,
        campaignBuilderLabel: null,
        marketingTemplatesEnabled: true,
        marketingTemplatesLabel: null,
        calendarEnabled: true,
        calendarLabel: null,
        landingPage: null,
        getNavLabel: null,
        authLogo: null,
        reviewPortalVanityDomain: null,
        organizationShortName: null,
        helpCenterUrl: null
      },
    };
  }

  @handle(SeedOrganizationPortalContextAction)
  private handleSeedOrganizationPortalContextAction(action: SeedOrganizationPortalContextAction) {
    const authLogo = defineDomainLogoUrl(action.portalContext, this.localStorageCache);

    this.setState((state) => ({
      ...state,
      isLoading: false,
      portalContext: action.portalContext,
      customization: { ...state.customization, authLogo: authLogo },
    }));
  }

  @handle(SeedGlobalDataRestrictionsAction)
  private async handleSeedGlobalDataRestrictionsAction(action: SeedGlobalDataRestrictionsAction) {
    const restrictions = await this.globalDataRestrictionsRepo.getApplicationRestrictions(this.state.season.Id);

    this.setState((state) => ({
        ...state,
        season: {
            ...this.state.season,
            DataPolicy: {
                ...this.state.season.DataPolicy,
                ApplicationRestrictions: restrictions
            }
        }
      }));
  }

  @handle(SetIsLoading)
  private handleSetIsLoading(action: SetIsLoading) {
    this.setState((state) => ({
      ...state,
      isLoading: action.isLoading,
    }));
  }

  @handle(StartAction)
  private handleStartAction(s: StartAction) {
    const customProperties = s.context.Organization.PropertyValues.reduce((acc, x) => {
      acc[x.FieldKey] = x.Value;
      return acc;
    }, {});

    this.setState((state) => ({
      ...state,
      organizationPortal: s.context.OrganizationPortal,
      organization: s.context.Organization,
      season: s.context.Season,
      paymentSettings: s.context.PaymentSettings,
      documentProviders: s.context.DocumentProviders,
      systemConfig: s.context.SystemConfig,
      pathTranslations: s.context.PathTranslations || [],
      customization: {
        ...state.customization,
        contactsEnabled: customProperties["organization_portal_navigation_contacts_enabled"] !== "false",
        contactsLabel: customProperties["organization_portal_navigation_contacts_label"],
        dashboardEnabled: customProperties["organization_portal_navigation_dashboard_enabled"] !== "false",
        dashboardLabel: customProperties["organization_portal_navigation_dashboard_label"],
        currentOrganizationShortNameEnabled: "org_short_name" in customProperties,
        currentOrganizationShortNameLabel: customProperties["org_short_name"],
        reportingEnabled: customProperties["organization_portal_navigation_reporting_enabled"] !== "false",
        reportingLabel: customProperties["organization_portal_navigation_reporting_label"],
        activityEnabled: customProperties["organization_portal_navigation_activity_enabled"] !== "false",
        activityLabel: customProperties["organization_portal_navigation_activity_label"],
        tasksEnabled: customProperties["organization_portal_navigation_tasks_enabled"] !== "false",
        tasksLabel: customProperties["organization_portal_navigation_tasks_label"],
        conversationsEnabled:
          customProperties["organization_portal_navigation_conversations_enabled"] !== "false",
        conversationsLabel: customProperties["organization_portal_navigation_conversations_label"],
        applicationsEnabled:
          customProperties["organization_portal_navigation_applications_enabled"] !== "false",
        applicationsLabel: customProperties["organization_portal_navigation_applications_label"],
        marketingEnabled: customProperties["organization_portal_navigation_marketing_enabled"] !== "false",
        marketingLabel: customProperties["organization_portal_navigation_marketing_label"],
        quickMessagesEnabled:
          customProperties["organization_portal_navigation_quickmessages_enabled"] !== "false",
        quickMessagesLabel: customProperties["organization_portal_navigation_quickmessages_label"],
        campaignBuilderEnabled:
          customProperties["organization_portal_navigation_campaignbuilder_enabled"] !== "false",
        campaignBuilderLabel: customProperties["organization_portal_navigation_campaignbuilder_label"],
        marketingTemplatesEnabled:
          customProperties["organization_portal_navigation_marketingtemplates_enabled"] !== "false",
        marketingTemplatesLabel: customProperties["organization_portal_navigation_marketingtemplates_label"],
        calendarEnabled: customProperties["organization_portal_navigation_calendar_enabled"] !== "false",
        calendarLabel: customProperties["organization_portal_navigation_calendar_label"],
        landingPage: customProperties["organization_portal_navigation_landing_page"],
        getNavLabel: function (labelType: string): string {
          return this[labelType] || NAV_BAR_DEFAULT_LABELS[labelType as NavBarLabelType];
        },

        reviewPortalVanityDomain: customProperties["review_portal_vanity_domain"],
        organizationShortName: customProperties["organization_short_name"],

        helpCenterUrl: customProperties["help_center_url"],
      },
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    const useOrganizationSwitcher = this.localStorageCache.get("use-organization-switcher");
    if (useOrganizationSwitcher) {
      this.setState(() => ({
        ...this.defaultState(),
      }));
      this.cache.remove("lastOrganizationPortalUrl");
      this.localStorageCache.remove("ats-portal-id");
    } else {
      this.setState((state) => ({
        ...state,
        organization: null,
        organizationPortal: null,
        paymentSettings: null,
        pathTranslations: [],
        notificationProfile: null,
      }));
    }
  }

  @handle(SwitchOrganizationAction)
  private async handleSwitchOrganization(so: SwitchOrganizationAction) {
    await this.userRepo.switchOrganization(so.organizationId);
    const context = await this.appRepo.organizationContext();
    //this.exceptionlessService.configure(context, false);

    if (context.Organization.Id != null) {
      context.ContactOrganization = await this.contactOrgRepo.getById(context.Organization.Id);
    }

    const portalContext = await this.appRepo.organizationPortalContext(context.OrganizationPortal.Domain);

    await dispatch(new SeedOrganizationPortalContextAction(portalContext));

    // reset the lazy load tracking for all states
    resetAllOnce();

    // signal app start
    await dispatch(new StartAction(context));

    this.cache.set("lastOrganizationPortalUrl", context.OrganizationPortal.Domain);
  }

  @handle(ActivatePaymentGatewayAction)
  private async handleActivatePaymentGateway(c: ActivatePaymentGatewayAction) {
    await this.paymentSettingsRepo.activateGateway(c.id);

    this.setState((state) => ({
      ...state,
      paymentSettings: {
        ...state.paymentSettings,
        ActiveGatewayId: c.id,
      },
    }));
  }

  @handle(SavePaymentGatewayAction, FormErrorHandling)
  private async handleCreatePaymentGateway(c: SavePaymentGatewayAction) {
    c.form.validate(c.form.Provider);

    let gateway: PaymentGateway = null;

    if (c.form.Id == null) {
      gateway = await this.paymentSettingsRepo.postGateway(c.form.updatedModel(), c.makeActive);
      this.state.paymentSettings.PaymentGateways.push(gateway);
    } else {
      gateway = await this.paymentSettingsRepo.putGateway(c.form.updatedModel(), c.makeActive);

      const copiedList = this.state.paymentSettings.PaymentGateways.slice();
      const existingGateway = copiedList.find((g) => g.Id == c.form.Id);

      if (existingGateway != null) {
        copiedList[copiedList.indexOf(existingGateway)] = gateway;
      }

      this.state.paymentSettings.PaymentGateways = copiedList;
    }

    if (c.makeActive) {
      this.state.paymentSettings.ActiveGatewayId = gateway.Id;
    }

    c.returnedPaymentGateway = gateway;

    this.setState((s) => s);
  }

  @handle(DeleteGatewayAction)
  private async handleDeleteGateway(d: DeleteGatewayAction) {
    await this.paymentSettingsRepo.deleteGateway(d.gatewayId);

    const { PaymentGateways } = this.state.paymentSettings;

    const gateway = PaymentGateways.find((g) => g.Id == d.gatewayId);
    if (gateway != null) {
      const idx = PaymentGateways.indexOf(gateway);
      PaymentGateways.splice(idx, 1);
      this.setState((s) => s);
    }
  }

  @handle(UpdateOrganizationAction, FormErrorHandling)
  private async handleUpdateOrganizationAction(action: UpdateOrganizationAction) {
    action.form.validate();

    const updatedOrganization = await this.orgRepo.put(this.state.organization.Id, {
      Name: action.form.Name,
      Timezone: action.form.Timezone,
      Domain: action.form.Domain,
    });

    this.setState((state) => ({
      ...state,
      organization: updatedOrganization,
    }));
  }

  @handle(UpdateOrganizationPortalAction, FormErrorHandling)
  private async handleUpdateOrganizationPortalAction(action: UpdateOrganizationPortalAction) {
    action.form.validate();

    const updatedOrganizationPortal = await this.orgPortalRepo.put(action.form.updatedModel());

    Object.assign(this.state.organizationPortal, updatedOrganizationPortal);
    this.setState((s) => s);

    // this.setState(state => ({
    //   ...state,
    //   organizationPortal: updatedOrganizationPortal,
    // }));
  }

  @handle(SetFeaturesAction)
  private async handleSetFeaturesAction(action: SetFeaturesAction) {
    const organization = await this.orgRepo.putActiveModules(this.state.organization.Id, {
      ModuleKeys: action.moduleKeys,
      Features: action.features,
    });

    await dispatch(new SwitchOrganizationAction(organization.Id));

    this.setState((state) => ({
      ...state,
      organization: organization,
    }));
  }

  public get hasDecisionsFeatureEnabled() {
    return this.state.organization.Features?.Decisions;
  }

  public get hasReviewPortalFeatureEnabled() {
    return this.state.organization?.Features?.ReviewPortal;
  }
}
