import { NetworkException, RouterConfig, inject } from "fw";
import { dispatch } from "fw-state";
import { ATS } from "network/ats";
import { InterviewPortalRepository } from "network/interview-portal-repository";
import { FeatureFlagService } from "service/feature-flag";
import { Notification } from "service/notification";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentUserStore } from "state/current-user";
import { EnsureInterviewPortalAction, InterviewPortalStore } from "state/interview-portal";
import { EnsurePortalsStoreAction, EnsureReviewPortalStoreAction, PortalsStore } from "state/portals";

@inject
export class MyToolsPopover {
  public router: RouterConfig = null;

  public constructor(
    private currentUser: CurrentUserStore,
    private currentOrganizationStore: CurrentOrganizationStore,
    private portalStore: PortalsStore,
    private interviewPortalRepo: InterviewPortalRepository,
    private interviewPortalStore: InterviewPortalStore,
    private ffs: FeatureFlagService,
    private notify: Notification,
    private network: ATS,
  ) { }

  public async activate(router: RouterConfig) {
    this.router = router;

    if (this.currentOrganizationStore.hasReviewPortalFeatureEnabled) {
      await dispatch(new EnsureReviewPortalStoreAction());
    }

    if (this.isInterviewFeatureEnabled) {
      await dispatch(new EnsureInterviewPortalAction());
    }
  }

  // -- review portal ---

  private get allowReviewPortalSwitch(): boolean {
    const reviewPortalEnabled = this.currentOrganizationStore.hasReviewPortalFeatureEnabled;
    const reviewPortal = this.portalStore.state.reviewPortals?.length > 0 ? this.portalStore.state.reviewPortals[0] : null;
    const associated = reviewPortal && !!this.currentUser.state.portalIds?.includes(reviewPortal.Id);
    return reviewPortalEnabled && reviewPortal && associated;
  }

  public async openReviewPortal() {
    if (!(this.portalStore.state.reviewPortals?.length > 0))
      return;

    const principalToken = this.network.hasImpersonationToken
      ? this.network.impersonationToken
      : this.network.token;

    const reviewPortalVanityDomain = this.currentOrganizationStore.state.customization.reviewPortalVanityDomain;
    const reviewPortal = this.portalStore.state.reviewPortals[0];
    const reviewPortalDomain = reviewPortalVanityDomain || reviewPortal.Domain;
    const url = `https://${reviewPortalDomain}/launch?token=${principalToken}`;

    window.location.href = url;
  }

  // -- interview portal --

  private get allowInterviewPortalSwitch(): boolean {
    const { Enabled:portalEnabled = false, InterviewerUserIds:interviewerIds = [], ProgramAssistantUserIds:assistantIds = [], ProgramDirectorUserIds:directorIds = [] } = this.interviewPortalStore.state?.interviewPortal || {};
    const userIsAssociated = [...interviewerIds, ...assistantIds, ...directorIds].includes(this.currentUser.state.user.Id);
    return this.isInterviewFeatureEnabled && portalEnabled && userIsAssociated;
  }

  private get isInterviewFeatureEnabled(): boolean {
    const ffInterviewManagement = this.ffs.isFeatureFlagEnabled("InterviewManagement");
    const ffViewInterviewPortalOnSidebar = this.ffs.isFeatureFlagEnabled("ViewInterviewPortalOnSidebar");
    const haveInterviewOrgFeature = !!this.currentOrganizationStore.state.organization?.Features?.InterviewPortal;
    return ffInterviewManagement && ffViewInterviewPortalOnSidebar && haveInterviewOrgFeature;
  }

  public async openInterviewPortal() {
    try {
      const returnUrl = `${window.location.protocol}//${window.location.hostname}`;
      const response = await this.interviewPortalRepo.linkInterviewPortal();
      const url = `${response.SsoUrl}&returnUrl=${returnUrl}`;
      window.open(url);
    } catch (e) {
      let message = `Error generating Interview Portal link`;
      if (e instanceof NetworkException && e.result?.Message) {
        message += `: ${e.result?.Message}`;
      }

      this.notify.error(message);
    }
  }
}
