import { ComponentEventBus, inject, prop } from "fw";
import { ApplicationRestriction } from "models/role";
import { ApplicationSettingsService } from "service/application-settings";

@inject
export class ApplicationPropertyRestriction {
  @prop(null) restriction!: ApplicationRestriction;
  @prop(false) disabled?: boolean;
  
  constructor(
    private ceb: ComponentEventBus,
    private applicationSettingsService: ApplicationSettingsService
  ) { }

  get results() {
    return this.restriction.Paths.map(p => {
      return this.getPathResult(p);
    });
  }

  getPathResult(path: string): { label: string, isValid: boolean } {
    const result: { label: string, isValid: boolean } = { label: "", isValid: false };
    if (!path || path.length === 0) return result;
    const property = this.applicationSettingsService.getApplicationPropertyFromPath(path);
    if (property) {
      result.label = property.Label;
      result.isValid = true;
    } else {
      result.label = path;
    }

    return result;
  }

  deletePath(index) {
    this.ceb.dispatch("delete-path", { restriction: this.restriction, index: index });
  }
}
