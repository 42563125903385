import { inject } from "fw";
import { range, toPath } from "lodash-es";
import {
  DataDictionaryAggregationType,
  DataDictionaryField,
  DataDictionaryFieldCategory,
  DataDictionaryFieldDataSource,
  DataDictionaryIndexStatus,
  SystemFieldType,
} from "models/data-dictionary";
import {
  allQuestions,
  CalculatedFieldDataTypeCode,
  Form,
  FormPathResult,
  FormSection,
  getName,
  Question,
  QuestionType,
  FormTypeCode,
  QuestionScaleMode,
} from "models/form";
import { FormRepository } from "network/form-repository";
import { FormStore } from "state/forms";
import { FeatureFlagService } from "./feature-flag";
import { ProgramStore } from "../state/program";
import { DataDictionaryStore } from "state/data-dictionary";
import { IDropdownFieldOptions } from "views/components/dropdown-field";

@inject
export class FormService {
  constructor(
    private formRepo: FormRepository,
    private formStore: FormStore,
    private ffs: FeatureFlagService,
    private programStore: ProgramStore,
    private dataDictionaryStore: DataDictionaryStore
  ) {}

  public getAllowSortingQuestionTypes() {
    return new Set([
      QuestionType.ShortText,
      QuestionType.Number,
      QuestionType.Date,
      QuestionType.DropDown,
      QuestionType.Name,
      QuestionType.CEEBCode,
      QuestionType.RadioButton,
    ]);
  }

  public getAllowGroupingQuestionTypes() {
    return new Set([
      QuestionType.ShortText,
      QuestionType.Number,
      QuestionType.Date,
      QuestionType.DropDown,
      QuestionType.CEEBCode,
      QuestionType.RadioButton,
    ]);
  }

  public async getFormPathResult(path: string): Promise<FormPathResult> {
    if (!path || path.length === 0) {
      return null;
    }

    const result: FormPathResult = {
      FormKey: null,
      QuestionKey: null,
      IsValid: false,
    };
    const form = await this.getFormFromPath(path);
    if (!form) {
      // form most likely deleted; try to display something
      const parts = toPath(path.startsWith("forms.") ? path.substring(6) : path);
      switch (parts && parts.length) {
        case 3:
          result.FormKey = parts[0];
          result.SectionKey = parts[1];
          result.QuestionKey = parts[2];
          break;
        case 2:
          result.FormKey = parts[0];
          if (parts[1] != "*") result.QuestionKey = parts[1];
          break;
        default:
          result.FormKey = path;
          break;
      }

      return result;
    }

    result.FormKey = form.Key;
    result.FormName = form.Name;
    result.FormTypeCode = form.MetaData.Type;
    result.FormTypeName = getName(form.MetaData.Type);

    if (path === `${form.Key}.*`) {
      result.IsValid = true;
      return result;
    }

    const section = this.resolveFormSectionFromPath(form, path);
    if (section && section.IsTableSection) {
      result.SectionTitle = section.Title;
      result.SectionKey = section.TableSectionOptions.Key;

      const question = this.resolveFormQuestionFromPath(form, path);
      if (question) {
        result.QuestionKey = question.Key;
        result.QuestionLabel = question.Label;
        result.QuestionText = question.Text;
        result.IsValid = true;
      } else {
        result.QuestionKey = path.substring(path.lastIndexOf(".") + 1);
      }

      return result;
    }

    const paths = toPath(path.startsWith("forms.") ? path.substring(6) : path);
    if (paths.length === 2) {
      const questionKey = paths[1];
      result.QuestionKey = questionKey;
      for (const sec of form.Sections) {
        if (result.IsValid) {
          continue;
        }

        if (sec.IsTableSection === false) {
          for (const q of sec.Questions) {
            if (q.Key === questionKey) {
              result.QuestionLabel = q.Label;
              result.QuestionText = q.Text;
              result.IsValid = true;
            }
          }
        }
      }

      if (!result.IsValid) {
        for (const calc of form.CalculatedFields) {
          if (result.IsValid) {
            continue;
          }

          if (calc.Key === questionKey) {
            result.QuestionLabel = calc.Label;
            result.QuestionText = calc.Label;
            result.IsValid = true;
          }
        }
      }
    }

    return result;
  }

  public async getFormFromPath(path: string): Promise<Form> {
    if (!path || path.length === 0) {
      return null;
    }

    const formPath = path.startsWith("forms.") ? path.substring(6) : path;
    if (!formPath || formPath.length === 0) {
      return null;
    }

    const paths = toPath(formPath);
    if (paths.length === 0) {
      return null;
    }

    const formKey = paths[0];
    if (!formKey || formKey.length === 0) {
      return null;
    }

    // PERF: We'll have to make this call and resolve how to call it if we want to get rid of forms on org context.
    //return await this.formRepo.getByKey(formKey);

    const forms = this.formStore.state.forms || [];
    return forms.find((f) => f.Key == formKey);
  }

  private resolveFormSectionFromPath(form: Form, path: string): FormSection {
    if (form == null) {
      return null;
    }

    const paths = toPath(path.startsWith("forms.") ? path.substring(6) : path);
    const tableSectionQuestionPathLength = 4;

    let sectionKey = paths.length === tableSectionQuestionPathLength ? paths[1] : null;
    if (!sectionKey || sectionKey.length === 0) {
      return null;
    }

    for (const sec of form.Sections) {
      if (sec.IsTableSection && sec.TableSectionOptions.Key == sectionKey) {
        return sec;
      }
    }

    return null;
  }

  public async getFormQuestionFromPath(path: string): Promise<Question> {
    const form = await this.getFormFromPath(path);
    return this.resolveFormQuestionFromPath(form, path);
  }

  public resolveFormQuestionFromPath(form: Form, path: string): Question {
    if (form == null) {
      return null;
    }

    const paths = toPath(path.startsWith("forms.") ? path.substring(6) : path);

    let sectionKey = "";
    let questionKey = "";
    if (paths.length === 2) {
      questionKey = paths[1];
    } else if (paths.length === 3) {
      sectionKey = paths[1];
      questionKey = paths[2];
    } else if (paths.length === 4) {
      sectionKey = paths[1];
      questionKey = paths[3];
    } else {
      return null;
    }

    for (const sec of form.Sections) {
      if (
        sectionKey.length > 0 &&
        (sec.IsTableSection === false || sec.TableSectionOptions.Key != sectionKey)
      ) {
        continue;
      }

      for (const q of sec.Questions) {
        if (q.Key == questionKey) {
          return q;
        }
      }
    }

    return null;
  }

  public getAggregateFieldsForEvalForm(formId: string) {
    const form = this.formStore.state.forms.find((f) => f.Id == formId);
    if (form == null) return [];

    const numberAndScaleQs = allQuestions(form).filter((q) =>
      [
        QuestionType.Number,
        QuestionType.Scale,
        QuestionType.RadioButton,
        QuestionType.DropDown,
        QuestionType.CheckBoxList,
      ].includes(q.Type)
    );

    const scaleGroupQs = allQuestions(form).filter((q) => q.Type == QuestionType.ScaleGroup);

    const calcFields = form.CalculatedFields.filter(
      (cf) => cf.DataType == CalculatedFieldDataTypeCode.Number
    );

    // maybe we should detect this ahead of time?
    if (numberAndScaleQs.length == 0 && calcFields.length == 0 && scaleGroupQs.length == 0) return [];

    const fieldOptions: { label: string; path: string }[] = [];

    numberAndScaleQs.forEach((f) => fieldOptions.push({ label: f.Key, path: `forms.${form.Key}.${f.Key}` }));

    scaleGroupQs.forEach((f) => {
      f.Options.ScaleGroup.Items.forEach((i) =>
        fieldOptions.push({
          label: `${f.Key} - ${i.Label}`,
          path: `forms.${form.Key}.${f.Key}.${i.Key}`,
        })
      );
      fieldOptions.push({
        label: `${f.Key} - Sum`,
        path: `forms.${form.Key}.${f.Key}.sum`,
      });
      fieldOptions.push({
        label: `${f.Key} - Average`,
        path: `forms.${form.Key}.${f.Key}.average`,
      });
    });

    calcFields.forEach((f) => fieldOptions.push({ label: f.Label, path: `forms.${form.Key}.${f.Key}` }));

    return fieldOptions;
  }

  // this entire process is concerned only with building dictionary entries for json/condition builder compatibility
  // the fields will not be fully realized
  public projectLocalFormDictionary(form: Form, formSection: FormSection = null): DataDictionaryField[] {
    const category = `Form: ${form.Name}`;

    const categoryEntry: DataDictionaryField = {
      FormKey: form.Key,
      Label: form.Name,
      Category: category,
      Path: null,
      JsonDataType: "object",

      // shame we need to declare these here...need to investigate optional fields on model
      IsRoutable: false,
      AggregationType: DataDictionaryAggregationType.None,
      IndexStatus: DataDictionaryIndexStatus.Unavailable,
      Description: null,
      IsTableSection: false,
      Sort: null,
      TaskRequestId: null,
      ProgramsUsingCount: 0,
      Values: []
    };

    let fields: DataDictionaryField[] = [];

    // collect all regular (non-table) section questions
    for (const section of form.Sections) {
      if (!section.IsTableSection) {
        for (const question of section.Questions) {
          const questionFields = this.projectLocalQuestionDictionaryFields(form, question, null, category);
          fields.push(...questionFields);
        }
      }
    }

    // if a tableSection was passed in, add its questions in at root level
    if (formSection?.IsTableSection) {
      for (const question of formSection.Questions) {
        const questionFields = this.projectLocalQuestionDictionaryFields(form, question, null, category);
        fields.push(...questionFields);
      }
    }

    if (
      this.ffs.isFeatureFlagEnabled("ApplicationFormsPublicProgramPropertiesConditions") &&
      form.MetaData.Type === FormTypeCode.Applicant
    ) {
      const programPublicPropertiesPaths = this.programStore.state.settings.ProgramProperties.filter(
        (item) => item.IsPublic
      ).map((item) => `program.properties.${item.Key}`);

      return [
        categoryEntry,
        ...fields,
        ...this.dataDictionaryStore.state.fields.filter(
          (item) =>
            item.Category === DataDictionaryFieldCategory.Program &&
            programPublicPropertiesPaths.includes(item.Path)
        ),
      ];
    }

    return [categoryEntry, ...fields];
  }

  private projectLocalQuestionDictionaryFields(
    form: Form,
    question: Question,
    parentJsonPath: string,
    category: string
  ) {
    let fields: DataDictionaryField[] = [];

    const dottedJsonPath = parentJsonPath != null && parentJsonPath.length > 0 ? `${parentJsonPath}.` : "";

    let values = [];
    if (question.Type === QuestionType.Scale) {
      values = question.Options.Scale.Mode === QuestionScaleMode.Labels
        ? question.Options.Scale.Values.map((item) => <IDropdownFieldOptions>{ text: item.Label, value: item.Value.toString() })
        : range(question.Options.Scale.Values[0].Value, question.Options.Scale.Values[1].Value, 1).map((item) => item.toString());
    } else {
      values = question.AnswerOptions.map((option) => option.Label)
    }

    let field: DataDictionaryField = {
      FormId: form.Id,
      FormKey: form.Key,
      FormPath: question.Key,
      Label: question.Label != null && question.Label.length > 0 ? question.Label : question.Key,
      Description: question.Text,
      ParentPath: parentJsonPath,
      Path: `${dottedJsonPath}${question.Key}`,
      Category: category,
      JsonDataType: "string",
      DataSource: DataDictionaryFieldDataSource.QuestionTypeCode,
      DataType: question.Type,

      AggregationType: DataDictionaryAggregationType.None,
      IndexStatus: DataDictionaryIndexStatus.Unavailable,
      Sort: null,
      IsTableSection: false,
      IsRoutable: false,
      TaskRequestId: null,
      ProgramsUsingCount: 0,
      Values: values,
      AllowsWriteInValue: question.Options?.AllowWriteIn,
    };

    // the condition builder doesn't use a lot of the dictionary fields, so we will only handle here what we must
    switch (question.Type) {
      case QuestionType.CheckBoxList:
      case QuestionType.Table:
        field.JsonDataType = "array";
        fields.push(field);
        break;
      case QuestionType.Number:
      case QuestionType.Scale:
        field.JsonDataType = "number";
        fields.push(field);
        break;
      case QuestionType.CEEBCode:
        field.JsonDataType = "object";
        fields.push(field);
        fields.push(this.makeSubField(field, "code", "Code"));
        fields.push(this.makeSubField(field, "name", "Name"));
        fields.push(this.makeSubField(field, "address", "Address"));
        fields.push(this.makeSubField(field, "city", "City"));
        fields.push(this.makeSubField(field, "region", "State/Region", "Region"));
        fields.push(this.makeSubField(field, "country", "Country"));
        fields.push(this.makeSubField(field, "postalCode", "Postal Code"));
        break;
      case QuestionType.Address:
        field.JsonDataType = "object";
        fields.push(field);
        fields.push(this.makeSubField(field, "address1", "Address 1"));
        fields.push(this.makeSubField(field, "address2", "Address 2"));
        fields.push(this.makeSubField(field, "city", "City"));
        fields.push(this.makeSubField(field, "region", "State/Region", "Region"));
        fields.push(this.makeSubField(field, "country", "Country"));
        fields.push(this.makeSubField(field, "postalCode", "Postal Code"));
        fields.push(this.makeSubField(field, "location", "Location"));
        break;
      case QuestionType.Name:
        field.JsonDataType = "object";
        fields.push(field);
        fields.push(this.makeSubField(field, "title", "Title"));
        fields.push(this.makeSubField(field, "given", "Given/First"));
        fields.push(this.makeSubField(field, "middle", "Middle"));
        fields.push(this.makeSubField(field, "family", "Family/Last"));
        fields.push(this.makeSubField(field, "suffix", "Suffix"));
        fields.push(this.makeSubField(field, "full", "Full"));
        break;
      case QuestionType.ScaleGroup:
        field.JsonDataType = "object";
        fields.push(field);
        for (const item of question.Options.ScaleGroup.Items) {
          let itemField = this.makeSubField(field, item.Key, item.Label);
          itemField.JsonDataType = "number";
          itemField.DataSource = DataDictionaryFieldDataSource.SystemFieldType;
          itemField.DataType = SystemFieldType.Number;
          fields.push(itemField);
        }
        break;
      case QuestionType.Encrypted:
        break;
      default:
        fields.push(field);
        break;
    }

    return fields;
  }

  private makeSubField(
    field: DataDictionaryField,
    key: string,
    label: string,
    description: string = null
  ): DataDictionaryField {
    let subField: DataDictionaryField = {
      FormId: field.FormId,
      FormKey: field.FormKey,
      FormPath: `${field.FormPath}.${key}`,
      Path: `${field.Path}.${key}`,
      ParentPath: field.Path,
      Category: field.Category,
      JsonDataType: "string",
      DataSource: DataDictionaryFieldDataSource.SystemFieldType,
      DataType: SystemFieldType.String,
      Label: `${field.Label} - ${label}`,
      Description: description?.length > 0 ? description : label,

      Sort: null,
      IsRoutable: false,
      IsTableSection: false,
      AggregationType: DataDictionaryAggregationType.None,
      TaskRequestId: null,
      IndexStatus: DataDictionaryIndexStatus.Unavailable,
      ProgramsUsingCount: 0,
    };
    return subField;
  }
}
