export const SETTINGS_PAGES = {
  General: "General",
  Users: "Users",
  GlobalDataRestrictions: "Global Data Restrictions",
  GeneralRoles: "Roles",
  Teams: "Teams",
  SSO: "SSO",
  CalendarEventTypes: "Event Types",
  ContactTypes: "Contact Types",
  ContactSegments: "Contact Segments",
  ContactSummaryViews: "Contact Summary Views",
  ContactRoutingTables: "Contact Routing Tables",
  CustomPortals: "Custom Portals",
  PendingContactsPage: "Pending Contacts",
  InquiryForms: "Inquiry Forms",
  CalendarInquiryForms: "Inquiry Forms",
  InquiryPortal: "Inquiry Portal",
  ActivityTemplates: "Activity Templates",
  Programs: "Programs",
  ProgramProperties: "Program Properties",
  ApplicationProperties: "Application Properties",
  ApplicantForms: "Applicant Forms",
  ApplicantPortal: "Applicant Portal",
  ReferenceForms: "Reference Forms",
  ReferencePortal: "Reference Portal",
  ContentTemplates: "Content Templates",
  StageTemplates: "Stage Templates",
  ApplicationSummaryView: "Application Summary View",
  EvaluationForms: "Evaluation Forms",
  Decisions: "Decisions",
  DecisionLetters: "Decision Letters",
  Phases: "Phases",
  ApplicationSegments: "Application Segments",
  ReviewRoutingTables: "Review Routing Tables",
  Automation: "Automation",
  Workflows: "Workflows",
  ApplicationWorkflows: "Workflows",
  ContactWorkflows: "Contact Workflows",
  EmailInfo: "Email Info",
  TextAndVoice: "Text and Voice",
  Conversations: "Conversations",
  SuppressedItems: "Suppressed Items",
  Portals: "Portals",
  Modules: "Modules",
  CollaborationRoles: "Roles",
  CollaborationTeams: "Teams",
  CollaborationRoutingTables: "Collaboration Routing Tables",
  ContactDataSources: "Contact Data Sources",
  FileImportSources: "File Import Sources",
  ApplicationImportSources: "Application Import Sources",
  Exports: "Exports",
  ExportDestinations: "Export Destinations",
  ExportPDFDesign: "Export PDF Design",
  TranslationTables: "Translation Tables",
  FieldDictionary: "Field Dictionary",
  ApplicationEmailTemplates: "Email Templates",
  EmailTemplates: "Email Templates",
  Payments: "Payments",
  APIKeys: "API Keys",
  KeyVault: "Key Vault",
  SystemActivity: "System Activity",
  CEEBData: "CEEB Data",
  FunctionLibrary: "Function Library",
  Metrics: "Metrics",
  GlobalAdmin: "Global Admin",
  InterviewPortal: "Interview Portal",
  StudentPortal: "Student Portal",
  ReviewPortal: "Review Portal",
  TransferPlannerPrograms: "Transfer Planner Programs",
  Workspaces: "Workspaces",
};
