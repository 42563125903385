import { inject, NetworkException } from "fw";
import { Store, handle } from "fw-state";
import { intersection } from "lodash-es";

import { once } from "helpers/once";
import {
  StartAction,
  LogoutAction,
  ContactOrganizationModelChangedAction,
  SelectedContactTypeChangedAction,
  SelectedContactTypeFilterChangedAction
} from "./actions";
import { ContactOrganization, CustomFieldType } from "models/contact-organization";
import { GroupFilter, FilterContext } from "models/filter-setup";
import { MarkerPoint, DEFAULT_MAPSTATE, MapState, getPrecisionFromZoom, boundingBoxToElasticFilter } from "models/map-state";
import { ContactRepository } from "network/contact-repository";
import { ContactOrganizationRepository } from "network/contact-organization-repository";
import { ContactsFilter } from "service/contacts-filter";
import { DataDictionaryStore } from "./data-dictionary";

export type ContactMapPoint = {
  id: string;
  name: string;
  address: string;
};

export type GeoFieldMappingInfo = {
  fieldId: string;
  displayName: string;
}

interface ContactsGeoStoreShape {
  loaded: boolean;
  organizationId: string;
  orgFieldsByContactType: { [contactType: string]: GeoFieldMappingInfo[] };
  contactType: string;
  currentFilter: GroupFilter;
  canMap: boolean;
  mappableFields: GeoFieldMappingInfo[];
  selectedFields: GeoFieldMappingInfo[];
  markers: MarkerPoint<ContactMapPoint>[];
  mapState: MapState;
}

export class EnsureContactsGeoStoreAction { }

export class ContactsGeoSelectFieldsAction {
  constructor(public selected: GeoFieldMappingInfo[]) { }
}

export class ContactsGeoUpdateMapAction {
  constructor(public s: MapState) { }
}

@inject
export class ContactsGeoStore extends Store<ContactsGeoStoreShape> {
  constructor(
    private contactsRepo: ContactRepository,
    private contactOrganizationRepository: ContactOrganizationRepository,
    private contactsFilter: ContactsFilter,
    private dataDictionaryStore: DataDictionaryStore
  ) {
    super();
  }

  protected defaultState() {
    return {
      loaded: false,
      organizationId: null,
      orgFieldsByContactType: null,
      contactType: null,
      currentFilter: null,
      canMap: false,
      mappableFields: [],
      selectedFields: [],
      markers: [],
      mapState: DEFAULT_MAPSTATE,
    };
  }

  @handle(StartAction)
  private async handleStart(action: StartAction) {
    const organizationId = action.context.Organization.Id;
    const contactType = action.context.UserSeasonSettings.Settings["contactsTypeSelected"];
    const currentFilter = this.contactsFilter.getFilterFor(organizationId, action.context.Me.Id, contactType);
    this.setState(_ => ({
      ...this.defaultState(),
      organizationId,
      contactType,
      currentFilter
    }));
  }

  @handle(LogoutAction)
  private handleLogout(action: LogoutAction) {
    this.setState(_ => this.defaultState());
  }

  @handle(SelectedContactTypeChangedAction)
  private async handleSelectedContactTypeChanged(action: SelectedContactTypeChangedAction) {
    if (!action.type)
      return;

    const { contactType } = this.state;
    if (contactType === action.type)
      return;

    await this.updateValues(action.type, action.filter);
  }

  @handle(SelectedContactTypeFilterChangedAction)
  private async handleSelectedContactTypeFilterChanged(action: SelectedContactTypeFilterChangedAction) {
    await this.updateValues(action.selectedContactTypeKey, action.filter);
  }

  @handle(ContactOrganizationModelChangedAction)
  private async handleContactOrganizationModelChangedAction(action: ContactOrganizationModelChangedAction) {
    await this.ensureOrganization(action.organization, true);
    const { contactType, currentFilter } = this.state;
    await this.updateValues(contactType, currentFilter);
  }

  @handle(EnsureContactsGeoStoreAction)
  private async handleEnsureContactsGeoStore(action: EnsureContactsGeoStoreAction) {
    await once("ensure-contacts-geo", async () => {
      await this.ensureOrganization();

      const { contactType, orgFieldsByContactType } = this.state;
      if (!contactType)
        return;

      const canMap = orgFieldsByContactType[contactType]?.length > 0;
      console.log(`Ensure() canMap=${canMap}`);
      const mappableFields = canMap ? orgFieldsByContactType[contactType] : [];
      this.setState(state => ({
        ...state,
        canMap,
        mappableFields
      }));
      await this.update();
    });
  }

  @handle(ContactsGeoSelectFieldsAction)
  private async handleContactsGeoSelectFields(action: ContactsGeoSelectFieldsAction) {
    this.setState(state => ({
      ...state,
      selectedFields: action.selected
    }));
    this.update();
  }

  private async updateValues(contactType: string, currentFilter: GroupFilter) {
    await this.ensureOrganization();

    const { orgFieldsByContactType } = this.state;
    const canMap = contactType && orgFieldsByContactType[contactType]?.length > 0;
    console.log(`updateValues() canMap=${canMap}`);
    const mappableFields = canMap ? orgFieldsByContactType[contactType] : [];

    this.setState(state => ({
      ...state,
      contactType,
      currentFilter,
      canMap,
      mappableFields,
      selectedFields: []
    }));

    await this.update();
  }

  private async ensureOrganization(organization: ContactOrganization = null, force: boolean = false) {
    const { organizationId, loaded } = this.state;
    if (loaded && !force)
      return;

    if (!organization)
      organization = await this.contactOrganizationRepository.getById(organizationId);

    const orgFieldsByContactType: { [contactType: string]: GeoFieldMappingInfo[] } = { };
    for (const field of organization.fields) {
      if (!field.is_indexed)
        continue;

        if ("address_geocoding" in field.data && !field.data.address_geocoding)
          continue;

      if (field.type !== CustomFieldType.address &&
          field.type !== CustomFieldType.postalcode &&
          field.type !== CustomFieldType.country)
        continue;

      const mapping = { fieldId: field.id, displayName: field.display_name };
      if (orgFieldsByContactType[field.contact_type])
        orgFieldsByContactType[field.contact_type].push(mapping);
      else
        orgFieldsByContactType[field.contact_type] = [mapping];
    }
    this.setState(state => ({...state, loaded: true, orgFieldsByContactType }));
  }

  private async update() {
    const { contactType, selectedFields } = this.state;
    if (!contactType)
      return;

    const points: MarkerPoint<any>[] = [];
    try {
      const filter = this.createFilter();
      const facet = this.createFacet();

      const aggregations = await this.contactsRepo.count(null,filter, facet, contactType);

      if (aggregations.total <= 100) {
        const contacts = await this.contactsRepo.list(null, filter, null, null, 1, 999999, contactType);

        for (const contact of contacts.results) {
          for (const geo of contact.geo_set) {
            if (selectedFields.length > 0 && intersection(geo.fields?.map(g => g.field_id) ?? [], selectedFields.map(s => s.fieldId)).length === 0)
              continue;

            const point: MarkerPoint<ContactMapPoint> = {
              data: {
                id: contact.id,
                name: contact.display_name,
                address: geo.full_address,
              },
              lat: geo.latitude,
              long: geo.longitude,
              total: 1,
            };

            points.push(point);
          }
        }

      } else {
        const aggs = aggregations.aggregations["geogrid_geo"];
        if (aggs) {
          for (const i of aggs.items) {
            points.push({ total: i.total, lat: i.aggregations.avg_lat.value, long: i.aggregations.avg_lon.value });
          }
        }
      }

    } catch (err) {
      if (err instanceof NetworkException && err.statusCode == 400) {
        console.warn(err.result.message);
      }
    }

    this.setState(state => ({
      ...state,
      markers: points,
    }));
  }

  private createFilter() {
    const geoFilter = '_exists_:geo';
    const { contactType } = this.state;
    const filterContext: FilterContext = {
      contactType: contactType,
      fields: this.dataDictionaryStore.state.fields
    };
    const userFilter = (this.state.contactType != null)
      ? this.state.currentFilter.toFilterString(filterContext)
      : null;
    const typeFilter = (contactType != null)
      ? `type:${contactType}`
      : null;
    let completeFilter = geoFilter;
    if (typeFilter != null) {
      completeFilter += ` ${typeFilter}`;
    }
    if (userFilter != null) {
      completeFilter += ` ${userFilter}`;
    }

    const bounding = this.state.mapState.bounding;
    if (bounding != null) {
      const bFilter = boundingBoxToElasticFilter(bounding);

      if (completeFilter.length > 0) {
        completeFilter = `(${completeFilter}) AND geo:${bFilter}`;
      } else {
        completeFilter = `geo:${bFilter}`;
      }
    }

    return completeFilter;
  }

  private createFacet() {
    return `geogrid:geo~${getPrecisionFromZoom(this.state.mapState.zoom)}`;
  }

  @handle(ContactsGeoUpdateMapAction)
  private async handleContactsGeoUpdateMap(action: ContactsGeoUpdateMapAction) {
    this.setState(state => ({
      ...state,
      mapState: action.s,
    }));

    this.update();
  }
}
