import { inject } from "fw";
import { Store, handle } from "fw-state";

import { LogoutAction, StartAction } from "./actions";
import { Form } from "models/form";

import { AddFormAction, EditFormAction, EditFormFormType, RefreshFormAction } from "forms/form";

import { FormRepository } from "network/form-repository";

import { FormErrorHandling } from "./error-handling";
import { difference } from "lodash-es";

interface FormShape {
  forms: Form[];
}

export class FormDeleteAction {
  constructor(public forms: string[]) {}
}

export class EnsureFormAction {
  constructor(public id: string) { }
}

const cleanForm = (form: EditFormFormType) => {
  if (form == null || form.Sections == null) return form;

  for (const section of form.Sections) {
    if (section.IsTableSection && section.TableSectionOptions != null) {
      if (section.TableSectionOptions.NamedRows != null && section.TableSectionOptions.NamedRows.length > 0) {
        section.TableSectionOptions.MinRowCount = 0;
        section.TableSectionOptions.MaxRowCount = 0;
      }
    } else {
      section.TableSectionOptions = null;
    }
  }

  return form;
};


@inject
export class FormStore extends Store<FormShape> {
  constructor(private formRepo: FormRepository) {
    super();
  }

  defaultState() {
    return {  
      forms: []
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {

    const sortedForms = s.context.Forms.sort((a, b) => {
      const nameA = a.Name.toLowerCase();
      const nameB = b.Name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });

    this.setState(state => ({
      ...state,
      forms: sortedForms
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureFormAction)
  private async handleEnsureFormAction(i: EnsureFormAction) {
    let form = this.state.forms.find(x => x.Id === i.id);
    if (form) {
      return;
    }

    form = await this.formRepo.get(i.id);

    this.setState(state => ({
      ...state,
      forms: [...state.forms, form]
    }));
  }

  @handle(FormDeleteAction)
  private async handleDelete(fd: FormDeleteAction) {
    const forms = this.state.forms.filter(f => fd.forms.some(id => id == f.Id));

    await this.formRepo.del(forms.map(f => f.Id));

    this.setState(state => ({
      ...state,
      forms: difference(state.forms, forms),
    }));
  }

  @handle(AddFormAction, FormErrorHandling)
  private async handleAddForm(i: AddFormAction) {
    const form = cleanForm(i.form);
    form.validate();

    const newForm = await this.formRepo.post(form.updatedModel(), i.formType, i.contactType);
    this.setState(state => ({
      ...state,
      forms: [...state.forms, newForm],
    }));

    i.newForm = newForm;
  }

  @handle(EditFormAction, FormErrorHandling)
  private async handleEditForm(i: EditFormAction) {
    const form = cleanForm(i.form);
    form.validate();

    const updatedForm = await this.formRepo.update(form.updatedModel(), i.version);

    const oldForm = this.state.forms.find(f => f.Id == updatedForm.Id);
    if (oldForm == null) return;

    Object.assign(oldForm, updatedForm);
    this.setState(s => s);
  }

  @handle(RefreshFormAction)
  private async handleRefreshForm(i: RefreshFormAction) {
    const updatedForm = await this.formRepo.get(i.id);

    const oldForm = this.state.forms.find(f => f.Id == updatedForm.Id);
    if (oldForm == null) {
      this.setState(state => ({
        ...state,
        forms: [...state.forms, updatedForm],
      }));
    }
    else {
      Object.assign(oldForm, updatedForm);
      this.setState(s => s);
    }
  }
}
