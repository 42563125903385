import { inject } from "fw";
import { dispatch, handle, Store } from "fw-state";

import {
  Conversation,
  ConversationType,
  Message,
  MessageDelivery,
  MessageMeta
} from "models/conversation";
import { User } from "models/user";
import { ConversationRepository } from "network/conversation-repository";
import { UserRepository } from "network/user-repository";
import { ConversationPermissionService } from "service/permissions/conversation";
import { LogoutAction, StartAction } from "state/actions";
import { LoadContactAction } from "state/current-contact";
import {
  filterEntityChangedMessage,
  EntityChanged,
  WebSocketMessageAction
} from 'state/filter-websocket-message';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_SORT = 'CreatedAt DESC';
const MAX_CONVERSATION_USERS_FETCHED = 20;

interface CurrentConversationStoreShape {
  contactId: string,
  conversation: Conversation;
  currentUserId: string;
  errorLoading: boolean;
  errorLoadingSummary: boolean;
  errorMessage: string;
  id: string;
  listType: ConversationType,
  loaded: boolean;
  loading: boolean;
  messages: Message[];
  messageCount: number;
  messageMeta: MessageMeta;
  page: number;
  pageSize: number;
  search: string;
  sort: string;
  users: User[];
}

export class EnsureCurrentConversationStoreAction {
  constructor() { }
}

export class ResetCurrentConversationStoreAction {
  constructor() { }
}

export class RefreshCurrentConversationStoreAction {
  constructor() { }
}

export class LoadCurrentConversationAction {
  constructor(
    public conversationId: string,
    public contactId?: string,
  ) { }
}

export class LoadMoreMessagesAction {
  constructor(public conversationId: string) { }
}

export class SetCurrentConversationListTypeAction {
  constructor(public type: ConversationType) { }
}

export class SetCurrentConversationSearchAction {
  constructor(public search: string) { }
}

export class AssignUserToCurrentConversationAction {
  constructor(public userId: string) { }
}

@inject
export class CurrentConversationStore extends Store<CurrentConversationStoreShape> {
  constructor(
    private conversationRepo: ConversationRepository,
    private conversationPermissionService: ConversationPermissionService,
    private userRepository: UserRepository,
  ) {
    super();
  }

  defaultState() {
    return {
      contactId: null,
      conversation: new Conversation(),
      currentUserId: null,
      errorLoading: false,
      errorLoadingSummary: false,
      errorMessage: null,
      listType: ConversationType.Text,
      id: null,
      loaded: false,
      loading: false,
      messages: [],
      messageCount: 0,
      messageMeta: new MessageMeta(),
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      search: '',
      sort: DEFAULT_SORT,
      users: [],
    };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    if (!this.state.loaded) {
      const defaultState = this.defaultState();
      defaultState.currentUserId = action.context.Me.Id;
      this.setState(s => defaultState);
    }
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  private getLastOutgoingMessageId(messages: Message[]): number {
    const lastOutgoingMessage: Message = messages.find((m) => {
      if (m && 'Delivery' in m?.target) {
        return MessageDelivery.Outgoing === m?.target?.Delivery;
      }
    });
    return (lastOutgoingMessage?.Id) ? parseInt(lastOutgoingMessage?.Id, 10) : 0;
}

  private getMessageMeta(messages: Message[]): MessageMeta {
    const LastOutgoingMessageId: number = this.getLastOutgoingMessageId(messages);
    return {
      LastOutgoingMessageId,
    };
  }

  private async getConversationUsers(messages: Message[]): Promise<User[]> {
    let users: User[] = [];
    let userIds: string[] = [];
    for (const message of messages as Message[]) {
      if ('SenderUserId' in message?.target) {
        // add users that have messaged in conversation
        userIds.push(message?.target?.SenderUserId);
      } else if ('UserId' in message?.target) {
        // add users that have updated conversation status
        userIds.push(message?.target?.UserId);
      }

      // both AssigneeUserId and AssignerUserId can be present
      if ('AssigneeUserId' in message?.target && message?.target.AssigneeUserId) {
        // add users that have been assigned the conversation
        userIds.push(message?.target.AssigneeUserId);
      }
      if ('AssignerUserId' in message?.target && message?.target?.AssignerUserId) {
        // add users that have reassigned the conversation
        userIds.push(message?.target.AssignerUserId);
      }
    }

    const uniqueUserIds = [...new Set(userIds)].filter((uid: string) => uid && '0' !== uid);
    if (uniqueUserIds.length > 0) {
      const { f, s, sort, page, pageSize } = {
        f: uniqueUserIds.join(' OR '),
        s: null,
        sort: null,
        page: 1,
        pageSize: MAX_CONVERSATION_USERS_FETCHED,
      };
      const usersResponse: User[] = (await this.userRepository.list(f, s, sort, page, pageSize)).list;
      for (const user of usersResponse) {
        users[user.Id] = user;
      }
    }
    
    return users;
  }

  private async loadConversation() {
    let errorMessage = null;

    if (!this.conversationPermissionService.canViewListing) {
      errorMessage = 'The current user does not have permission to view this conversation.';
      this.setState(state => ({
        ...state,
        errorLoading: true,
        errorMessage,
        loaded: true,
        loading: false,
      }));

      return;
    }

    this.setState(state => ({
      ...state,
      loading: true,
    }));

    let conversation: Conversation = new Conversation();
    let messages: Message[] = [];
    let messageCount: number = 0;

    if (!!this.state.id) {
      try {
        [conversation, { messages, total: messageCount }] = await Promise.all([
          this.conversationRepo.get(this.state.id),
          this.conversationRepo.getTimeline(this.state.id, this.state.search),
        ]);
      } catch (response) {
        errorMessage = 'Error loading conversation.';
        if (response?.result?.errors?.length) {
          errorMessage = response?.result?.errors?.join(' ');
        }

        this.setState(state => ({
          ...state,
          errorLoading: true,
          errorMessage,
          loaded: true,
          loading: false,
        }));

        return;
      }
    }

    if (!this.conversationPermissionService.canView(conversation?.AssignedToUserId)) {
      errorMessage = 'The current user does not have permission to view this conversation.';
      this.setState(state => ({
        ...state,
        errorLoading: true,
        errorMessage,
        loaded: true,
        loading: false,
      }));

      return;
    }

    if (!conversation?.ContactId) {
      conversation.ContactId = this.state.contactId;
    }
    try {
      // LoadContactAction will immediately return if the contact is already loaded
      await dispatch(new LoadContactAction(conversation?.ContactId));
    } catch (response) {
        // ignore error, we want to show the page even if we can't find the contact
    }

    const users: User[] = await this.getConversationUsers(messages);
    const messageMeta = this.getMessageMeta(messages);

    this.setState(state => ({
      ...state,
      conversation,
      contactId: conversation?.ContactId,
      errorLoading: false,
      errorMessage,
      loaded: true,
      loading: false,
      messages,
      messageCount,
      messageMeta,
      users,
    }));
  }

  private async loadMoreMessages() {
    this.setState(state => ({
      ...state,
      loading: true,
    }));

    let errorMessage = null;
    let messages: Message[] = [];
    let messageCount: number = 0;
    let page = this.state.page + 1;

    try {
      const { id, pageSize, search, sort } = this.state;
      ({ messages, total: messageCount } = await this.conversationRepo.getTimeline(id, search, sort, page, pageSize));
    } catch (response) {
      errorMessage = 'Error loading more messages.';
      if (response?.result?.errors?.length) {
        errorMessage = response?.result?.errors?.join(' ');
      }

      this.setState(state => ({
        ...state,
        errorLoading: true,
        errorMessage,
        loaded: true,
        loading: false,
      }));

      return;
    }

    messages = [...this.state.messages, ...messages];
    const users: User[] = await this.getConversationUsers(messages);

    this.setState(state => ({
      ...state,
      errorLoading: false,
      errorMessage,
      loaded: true,
      loading: false,
      messages,
      messageCount,
      page,
      users,
    }));
  }

  @handle(EnsureCurrentConversationStoreAction)
  private async handleEnsureCurrentConversationStoreAction(action: EnsureCurrentConversationStoreAction) {
    if (this.state.loading || this.state.loaded) {
      return;
    }
    await this.loadConversation();
  }

  @handle(ResetCurrentConversationStoreAction)
  private async handleResetCurrentConversationStoreAction(action: ResetCurrentConversationStoreAction) {
    this.setState(s => ({
      ...this.defaultState(),
    }));
  }

  @handle(RefreshCurrentConversationStoreAction)
  private async handleRefreshCurrentConversationStoreAction(action: RefreshCurrentConversationStoreAction) {
    if (this.state.loading) return;
    await this.loadConversation();
  }

  @handle(LoadCurrentConversationAction)
  private async handleLoadCurrentConversationAction(action: LoadCurrentConversationAction) {
    if (this.state.loaded && this.state.conversation?.Id == action.conversationId) {
      return;
    }
    this.setState(s => ({
      ...this.defaultState(),
      id: action.conversationId,
      contactId: action?.contactId,
    }));
    await this.loadConversation();
  }

  @handle(LoadMoreMessagesAction)
  private async handleLoadMoreMessagesAction(action: LoadMoreMessagesAction) {
    if (this.state.conversation.Id != action.conversationId) {
      return;
    }
    await this.loadMoreMessages();
  }

  @handle(SetCurrentConversationListTypeAction)
  private async handleSetCurrentConversationListTypeAction(action: SetCurrentConversationListTypeAction) {
    const { type: listType } = action;
    this.setState(state => ({
      ...state,
      listType,
    }));
    await this.loadConversation();
  }

  @handle(SetCurrentConversationSearchAction)
  private async handleSetCurrentConversationSearchAction(action: SetCurrentConversationSearchAction) {
    if (this.state.loading) {
      return;
    }
    this.setState(state => ({
      ...state,
      loaded: false,
      loading: false,
      search: action.search,
      total: 0,
    }));
    await this.loadConversation();
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("ConversationChangedEvent"))
  private async handleWebSocketMessageAction(action: WebSocketMessageAction<EntityChanged>) {
    const data = action?.data?.data;
    if (data.conversation?.Id === this.state.conversation.Id)
      this.handleRefreshCurrentConversationStoreAction(null);
  }
}
