import { fromClass, fromClassArray } from "fw-model";
import { ApplicationSettings } from "./application-settings";
import { ApplicantSettings } from "./applicant-settings";
import { EvaluationPhase } from "./evaluation-phase";
import { DecisionSettings } from './decision-settings';
import { ProgramSettings } from "./program-settings";
import { DataPolicy } from "./role";

export class OrganizationPortalMetaData {
  OrganizationId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class CalculatedFieldValue {
  FieldKey: string = null;
  Value: string = null;
}

export class Features {
  InterviewPortal: boolean = false;
  AssignmentNotifications: boolean = true;
  Decisions: boolean = false;
  ReviewPortal: boolean = false;
}

export class OrganizationPortalContent {
  CompactLogoFileId: string | null;
  CompactLogoLightFileId: string | null;
  CompactLogoLightUrl: string | null;
  CompactLogoUrl: string | null;
  FullLogoFileId: string | null;
  FullLogoLightFileId: string | null;
  FullLogoLightUrl: string | null;
  FullLogoUrl: string | null;
  WelcomeMessageBGImageFileId: string | null;
  WelcomeMessageBGImageUrl: string | null;
}

export class OrganizationPortal {
  Id: string;
  AllowLocalLogin = true;

  Domain: string = null;
  IdentityProviderIds: string[] = [];
  Content: OrganizationPortalContent;
  BrowserTitle: string | null;
  @fromClass MetaData: OrganizationPortalMetaData;
}

export class OrganizationContact {
  Name: string;
  PhoneNumber: string;
  EmailAddress: string;
}

export class Season {
  Id: string;
  Name: string;
  DefaultPhaseLabel: string;

  @fromClass ApplicationSettings: ApplicationSettings;
  @fromClass ApplicantSettings: ApplicantSettings;
  @fromClass DataPolicy: DataPolicy;
  @fromClassArray(EvaluationPhase) EvaluationPhases: EvaluationPhase[];
  @fromClass DecisionSettings: DecisionSettings;
  @fromClass ProgramSettings: ProgramSettings;
}

export enum AccountType {
  Customer,
  Demo,
  Internal,
  Template,
  Testing,
}
export class OrganizationMetaData {
  Type: AccountType;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  SalesForceId: string;
}
export class Organization {
  Id: string;
  Name: string;
  Timezone: string;
  CountryCode: string;
  IsActive: boolean;
  IsDeleted: boolean;
  ActiveSeasonId: string;
  ActiveModuleKeys: string[];
  ActiveFeatures: string[];
  Tags: string[];
  Features: Features;

  @fromClass MetaData: OrganizationMetaData;
  @fromClass Contact: OrganizationContact;
  @fromClassArray(CalculatedFieldValue) PropertyValues: CalculatedFieldValue[];
  @fromClassArray(Season) Seasons: Season[];
}

export enum PaymentProviderTypeCode {
  Stripe = 0,
  AuthorizeDotNet = 1,
  Cybersource = 2,
  CybersourceSA = 3,
  TouchNet = 4,
}

export class PaymentGatewayData {
  apiLogin: string = ""; // auth.net

  profileId: string = ""; // cybersource
  merchantId: string = ""; // cybersource
  apiKeyId: string = ""; // cybersource
  apiKeySharedSecret: string = ""; // cybersource

  username: string = ""; // touchnet
  password: string = ""; // touchnet
  uPayId: string = ""; // touchnet
  endpoint: string = ""; // touchnet
  uPayUrl: string = ""; // touchnet
  timeout: number = 30; //touchnet (seconds)
}

export class PaymentGateway {
  Id: string;
  Provider: PaymentProviderTypeCode = null;
  PublicKey: string = null;
  PrivateKey: string = null;
  Production = false;
  @fromClass Data: PaymentGatewayData;
}

export class PaymentSettings {
  Id: string;
  ActiveGatewayId: string;

  @fromClassArray(PaymentGateway) PaymentGateways: PaymentGateway[];
}

export class ModuleConfiguration {
  Key: string;
  Features: string[];
}

export class TrackConfiguration {
  Key: string;
  @fromClassArray(ModuleConfiguration) Modules: ModuleConfiguration[];
}

export class OrganizationPostArgs {
  Name: string = null;
  Timezone: string = null;
  Domain: string = null;
}

export class OrganizationPutArgs {
  Name: string = null;
  Timezone: string = null;
  Domain: string = null;
}

export type ModuleResolved = {
  name: string;
  description: string;
  iconKey: string;
};

// can probably
export const resolveModuleKey = (key: string): ModuleResolved => {
  switch (key) {
    case "review": return {
      name: "Application Review",
      description: "Review and manage applications received through an integration.",
      iconKey: "local_library",
    };
    case "portal": return {
      name: "Student Gateway",
      description: "Create a custom-branded portal to accept applications online.",
      iconKey: "laptop_chromebook",
    };
    case "workflow": return {
      name: "Workflow",
      description: "Automate your admissions pipeline with configurable triggers and actions.",
      iconKey: "call_split",
    };

    case "contacts": return {
      name: "Contacts",
      description: "Manage your network of prospects, applicants, and other important relationships.",
      iconKey: "group",
    };
    case "marketing": return {
      name: "Marketing",
      description: "Optimize enrollment with automated email, text, print and voice messaging campaigns.",
      iconKey: "wifi_tethering",
    };
    case "eventsandtravel": return {
      name: "Events & Travel",
      description: "Coordinate planning and attendance both on-campus and off-campus.",
      iconKey: "event_note",
    };
  }

  return {
    name: "",
    description: "",
    iconKey: "",
  };
}

export const resolveFeature = (key: string) => {
  switch (key) {
    case "Feature.Reports": return "Reports";
    case "Feature.Teams": return "Teams";
    case "Feature.ApplicationReview": return "Application Review";
    case "Feature.ApplicationExports": return "Application Exports";
    case "Feature.Dashboard": return "Personalize Dashboard";
    case "Feature.ApplicantPortal": return "Applicant Portals";
    case "Feature.Payments": return "Payments";
    case "Feature.References": return "References";
    case "Feature.Stages": return "Stages";
    case "Feature.ApplicationWorkflow": return "Application Workflow";
    case "Feature.Collaboration": return "Collaboration";
    case "Feature.Contacts": return "Contacts";
    case "Feature.ContactExports": return "Contact Exports";
    case "Feature.Marketing": return "Marketing Outreach";
    case "Feature.MarketingEvents": return "Events";
    case "Feature.MarketingTravel": return "Travel";
  }

  return "";
}

export const resolveTrackKey = (key: string) => {
  switch (key) {
    case "application": return "Admissions";
    case "recruitment": return "Recruitment";
  }

  return "";
}
