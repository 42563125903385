import { fromClass } from "fw-model";

export enum InvitationStatus {
  ReadyToSend = 0,
  Sent = 1
}

export class PortalInvitationMetaData {
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  OrganizationId: string;

  FirstName: string;
  LastName: string;
  EmailAddress: string;

  ExpiryUtc: string;

  Status = InvitationStatus.ReadyToSend;
}

export class PortalInvitation {
  Id: string = null;
  PortalId: string = null;
  UserId: string = null;

  @fromClass MetaData: PortalInvitationMetaData;
}

export class InviteContext {
  UserId: string;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  NeedsLocalPassword: boolean;
  OrganizationName: string;
}
